import { useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import PersonIcon from "@mui/icons-material/Person";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Badge from "@mui/material/Badge";
import * as Realm from "realm-web";

const Container = styled.div`
    flex: 0.2;
    display: flex;
    justify-content: center;
`;

const IconAnchor = styled.a``;

const Icon = styled.div`
    display: flex;
    justify-content: center;
    margin: 0px;
    color: black;
    padding: 0px 9px;
`;

const appId = "digital-mushi-0-wohdm";
const appConfig = {
    id: appId,
    timeout: 10000,
};

const RealmInstance = new Realm.App(appConfig);

const Right = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    const CART = useSelector((state) => state.Cart);
    const AUTH = useSelector((state) => state.Auth);
    const isAuthorised = AUTH.isAuthorised;

    const currentRealmUser = RealmInstance.currentUser;

    async function realmLogout() {
        await currentRealmUser?.logOut();
    }

    const calculateCartLength = () => {
        if (typeof CART.items == "object") {
            return Object.keys(CART.items).length;
        } else {
            return 0;
        }
    };

    const cartLength = calculateCartLength();

    return (
        <Container>
            <IconAnchor href="/collections">
                <Icon>
                    <SearchOutlinedIcon />
                </Icon>
            </IconAnchor>

            <IconAnchor href="/cart">
                <Icon>
                    <Badge badgeContent={cartLength} color="primary">
                        <ShoppingCartOutlinedIcon />
                    </Badge>
                </Icon>
            </IconAnchor>

            {isAuthorised ? (
                <IconAnchor href="/order">
                    <Icon>
                        <PersonIcon />
                    </Icon>
                </IconAnchor>
            ) : (
                <IconAnchor href="/login">
                    <Icon>
                        <AccountCircleOutlinedIcon />
                    </Icon>
                </IconAnchor>
            )}
        </Container>
    );
};

export default Right;
