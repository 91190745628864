import { useSelector } from "react-redux";
import styled from "styled-components";

const AccountLayout = ({ children }) => {
    const AUTH = useSelector((state) => state.Auth);
    const isAuthorised = AUTH.isAuthorised;

    if (isAuthorised) {
        return <>{children}</>;
    } else {
        window.location.replace("/");
    }
};

export default AccountLayout;
