import { ListItemSecondaryAction } from "@mui/material";
import styled from "styled-components";

import ProductSkeleton from "../../Components/Skeleton/ProductSkeleton";

const Filters = styled.div`
	display: flex;
	background: #f0eeee;
	padding: 7px 11px;
	margin-bottom: 6px;
	justify-content: space-between;
	@media (max-width: 642px) {
		display: block;
		padding: 12px 16px;
		margin-bottom: 22px;
		border-radius: 3px;
		box-shadow: 0px 0px 8px #dbdbdb;
	}
`;
const Textarea = styled.div`
	display: flex;
	font-family: initial;
	align-items: center;
	@media (max-width: 870px) {
		font-size: 13px;
	}
	@media (max-width: 642px) {
		display: block;
		font-size: 14px;
		width: 100%;
	}
`;
const Div = styled.div`
	display: flex;
`;

const Description = styled.div`
	margin-left: 6px;
	padding: 3px 10px;
	background: #d5d5d5;
	@media (max-width: 642px) {
		margin-left: 0px;
	}
`;
const Rupee = styled.div`
	margin-left: 6px;
	background: #d5d5d5;
	padding: 3px 10px;
	@media (max-width: 642px) {
		width: 100%;
		margin-left: 0px;
		padding: 3px 10px;
	}
`;
const CartButton = styled.div`
	width: 19%;
	@media (max-width: 642px) {
		width: 100%;
	}
`;
const Button = styled.button`
	font-size: 14px;
	display: block;
	text-decoration: none;
	text-align: center;
	padding: 8px 12px;
	background: black;
	color: white;
	border-radius: 5px;
	@media (max-width: 900px) {
		font-size: 11px;
	}
	@media (max-width: 642px) {
		margin-top: 10px;
		font-size: 14px;
	}
	&:hover {
		background: #3e3b3b;
	}
`;
const Headingtext = styled.h2`
	font-size: 14px;
	padding: 3px 10px;
	margin-left: 6px;
	color: gray;
	background: #d5d5d5;
	font-weight: bold;
	@media (max-width: 642px) {
		margin-left: 0px;
	}
`;
const Search = ({ product, isLoading }) => {
	const data = [
		{
			id: 1,
			heading: "SKD",
			description: "KASHMIR WILLOW CRICKET BAT 99.",
			rupee: "₹3221",
			button: "Add To Quote",
		},
		{
			id: 1,
			heading: "SKD",
			description: "KASHMIR WILLOW CRICKET BAT 99.",
			rupee: "₹3221",
			button: "Add To Quote",
		},
		{
			id: 1,
			heading: "SKD",
			description: "KASHMIR WILLOW CRICKET BAT 99.",
			rupee: "₹3221",
			button: "Add To Quote",
		},
		{
			id: 1,
			heading: "SKD",
			description: "KASHMIR WILLOW CRICKET BAT 99.",
			rupee: "₹3221",
			button: "Add To Quote",
		},
		{
			id: 1,
			heading: "SKD",
			description: "KASHMIR WILLOW CRICKET BAT 99.",
			rupee: "₹3221",
			button: "Add To Quote",
		},
		{
			id: 1,
			heading: "SKD",
			description: "KASHMIR WILLOW CRICKET BAT 99.",
			rupee: "₹3221",
			button: "Add To Quote",
		},
		{
			id: 1,
			heading: "SKD",
			description: "KASHMIR WILLOW CRICKET BAT 99.",
			rupee: "₹3221",
			button: "Add To Quote",
		},
	];

	return (
		<>
			{isLoading ? (
				<ProductSkeleton limit={20} />
			) : (
				product?.map((item, index) => {
					return (
						<Filters key={index.toString()}>
							<Textarea>
								<Headingtext>{item.brand}</Headingtext>
								<Description>{item.product_name}</Description>
								<Div>
									<Rupee>MRP: {item.base_price} </Rupee>
								</Div>
							</Textarea>
							<CartButton>
								<Button as="a" href="/emptycart">
									Add To Quote
								</Button>
							</CartButton>
						</Filters>
					);
				})
			)}
		</>
	);
};

export default Search;
