export const getOrderFn = async ({ queryKey }) => {
    const [, url, axiosPrivate] = queryKey;
    try {
        const data = await axiosPrivate.get(url);
        return data?.data;
    } catch (error) {
        return error;
    }
};

export const addOrderFn = async (postData, route, axiosPrivate) => {
    try {
        const data = await axiosPrivate.post(route, postData);
        return data?.data;
    } catch (error) {
        return error;
    }
};

export const updateOrderFn = async (postData, route, axiosPrivate) => {
    try {
        const data = await axiosPrivate.post(route, postData);
        return data?.data;
    } catch (error) {
        return error;
    }
};
