import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";

export const footer = {
    widgets: [
        {
            id: 1,
            widgetTitle: "social",
            lists: [
                {
                    id: 1,
                    title: "instagram",
                    path: "https://www.instagram.com/vishwasportsco/",
                    icon: <InstagramIcon />,
                },
                {
                    id: 2,
                    title: "twitter",
                    path: "https://www.google.com",
                    icon: <TwitterIcon />,
                },
                {
                    id: 3,
                    title: "facebook",
                    path: "https://www.facebook.com/vishwasports/",
                    icon: <FacebookIcon />,
                },
                {
                    id: 4,
                    title: "youtube",
                    path: "https://www.google.com",
                    icon: <YouTubeIcon />,
                },
            ],
        },
        {
            id: 2,
            widgetTitle: "About Us",
            lists: [
                {
                    id: 3,
                    title: "About us",
                    path: "/about",
                },
            ],
        },

        {
            id: 3,
            widgetTitle: "Help",
            lists: [
                {
                    id: 1,
                    title: "Faq",
                    path: "/faq",
                },
                {
                    id: 3,
                    title: "customer support",
                    path: "/",
                },
            ],
        },
        {
            id: 4,
            widgetTitle: "Information",
            lists: [
                {
                    id: 1,
                    title: "Privacy Policy",
                    path: "/privacy",
                },
                // {
                //     id: 2,
                //     title: "Terms & Conditions",
                //     path: "/termsandConditions",
                // },
                // {
                //     id: 3,
                //     title: "Disclaimer",
                //     path: "/disclaimer",
                // },
            ],
        },
        {
            id: 5,
            widgetTitle: "contact",
            lists: [
                {
                    id: 1,
                    title: "Vishwa Sports Co",
                    path: "/contact",
                },
                {
                    id: 2,
                    title: "Suraj Kund Road Meerut, 0121-4056790, 4331774, 9719206654",
                    path: "/contact",
                },
                {
                    id: 3,
                    title: "care@vishwasports.com",
                    path: "/contact",
                },
                {
                    id: 4,
                    title: "Call us: +91-120-4720800",
                    path: "/contact",
                },
            ],
        },
    ],
    payment: [
        {
            id: 1,
            path: "/",
            image: "/assets/images/payment/mastercard.svg",
            name: "payment-master-card",
            width: 34,
            height: 20,
        },
        {
            id: 2,
            path: "/",
            image: "/assets/images/payment/visa.svg",
            name: "payment-visa",
            width: 50,
            height: 20,
        },
        {
            id: 3,
            path: "/",
            image: "/assets/images/payment/paypal.svg",
            name: "payment-paypal",
            width: 76,
            height: 20,
        },
        {
            id: 4,
            path: "/",
            image: "/assets/images/payment/jcb.svg",
            name: "payment-jcb",
            width: 26,
            height: 20,
        },
        {
            id: 5,
            path: "/",
            image: "/assets/images/payment/skrill.svg",
            name: "payment-skrill",
            width: 39,
            height: 20,
        },
    ],
};
