class Order_m {
    constructor(
        customerId,
        createdBy,
        firstName,
        lastName,
        customerEmail,
        customerWhatsappNo,
        orderAmount,
        status,
        message,
        orderDate,
        lastUpdatedOn
    ) {
        this.customer_id = customerId;
        this.createdBy = createdBy;
        this.firstName = firstName;
        this.lastName = lastName;
        this.customerEmail = customerEmail;
        this.customerWhatsappNo = customerWhatsappNo;
        this.orderAmount = orderAmount;
        this.status = status;
        this.message = message;
        this.orderDate = orderDate;
        this.lastUpdatedOn = lastUpdatedOn;
    }

    get getCustomerId() {
        if (
            this.customer_id == undefined ||
            this.customer_id == null ||
            this.customer_id == ""
        ) {
            return;
        } else {
            return this.customer_id;
        }
    }

    get getCreatedBy() {
        if (
            this.createdBy == undefined ||
            this.createdBy == null ||
            this.createdBy == ""
        ) {
            return;
        } else {
            return this.createdBy;
        }
    }

    get getFirstName() {
        if (
            this.firstName == undefined ||
            this.firstName == null ||
            this.firstName == ""
        ) {
            return;
        } else {
            return parseInt(this.firstName);
        }
    }

    get getLastName() {
        if (
            this.lastName == undefined ||
            this.lastName == null ||
            this.lastName == ""
        ) {
            return;
        } else {
            return this.lastName;
        }
    }

    get getCustomerEmail() {
        if (
            this.customerEmail == undefined ||
            this.customerEmail == null ||
            this.customerEmail == ""
        ) {
            return;
        } else {
            return this.customerEmail;
        }
    }

    get getCustomerWhatsappNo() {
        if (
            this.customerWhatsappNo == undefined ||
            this.customerWhatsappNo == null ||
            this.customerWhatsappNo == ""
        ) {
            return;
        } else {
            return this.customerWhatsappNo;
        }
    }

    get getOrderAmount() {
        if (
            this.orderAmount == undefined ||
            this.orderAmount == null ||
            this.orderAmount == ""
        ) {
            return;
        } else {
            return this.orderAmount;
        }
    }

    get getStatus() {
        if (
            this.status == undefined ||
            this.status == null ||
            this.status == ""
        ) {
            return;
        } else {
            return this.status;
        }
    }

    get getOrderItems() {
        if (
            this.orderItems == undefined ||
            this.orderItems == null ||
            this.orderItems == ""
        ) {
            return;
        } else {
            return this.orderItems;
        }
    }

    get getOrderDate() {
        if (
            this.orderDate == undefined ||
            this.orderDate == null ||
            this.orderDate == ""
        ) {
            return;
        } else {
            return this.orderDate;
        }
    }

    get getLastUpdatedOn() {
        if (
            this.lastUpdatedOn == undefined ||
            this.lastUpdatedOn == null ||
            this.lastUpdatedOn == ""
        ) {
            return;
        } else {
            return this.lastUpdatedOn;
        }
    }

    getOrderItem(orderItem) {
        const items = orderItem?.map((item, idx) => {
            return {
                product_id: item.product_id,
                brand: item.brand_name,
                product_name: item.product_name,
                mrp: item.mrp,
                dealer_price: item.dealer_price,
                order_qty: item.order_qty,
                available_qty: item.order_qty,
                rate_type: "mrp",
                discount: 0,
                price_by_rate_type: item.mrp,
                net_rate: item.mrp,
                price_after_discount: item.mrp,
                gross_margin: 0,
                gst: this.getGst(item.gst),
                price_per_product_after_gst: this.caculatePriceWithTax(
                    item.gst,
                    item.mrp
                ),
                customer_net_margin: this.calculateNetMargin(
                    item.mrp,
                    this.caculatePriceWithTax(item.gst, item.mrp)
                ),
                total_amount_per_item_with_gst: this.totalAmountPerItemWithGst(
                    item.gst,
                    item.mrp,
                    item.order_qty
                ),
                total_amount_per_item_without_gst:
                    this.totalAmountPerItemWithoutGst(item.mrp, item.order_qty),
            };
        });

        // console.log("item item ", items);

        return items;
    }

    totalAmountPerItemWithGst(gst, mrp, qty) {
        return this.caculatePriceWithTax(gst, mrp) * parseFloat(qty);
    }

    totalAmountPerItemWithoutGst(mrp, qty) {
        return parseFloat(mrp) * parseFloat(qty);
    }

    // private
    caculatePriceWithTax(gstValue, netRateValue) {
        const gst = parseFloat(gstValue);
        const netRate = parseFloat(netRateValue);
        const priceAfterTax = parseFloat(
            netRate + (netRate * gst) / 100
        ).toFixed(2);
        return parseFloat(priceAfterTax);
    }

    // private
    getGst(gst) {
        return parseFloat(gst.toFixed(2));
    }

    // private
    calculateNetMargin(mrpValue, priceAfterTaxValue) {
        const mrp = parseFloat(mrpValue);
        const priceAfterTax = parseFloat(priceAfterTaxValue);
        const netMargin = 100 - (priceAfterTax / mrp) * 100;
        return parseFloat(netMargin.toFixed(2));
    }

    // private
    calculateNetRate(discountValue, priceByRateType) {
        const discount = parseFloat(discountValue);
        const price = parseFloat(priceByRateType);
        const netRate = price - (price * discount) / 100;
        return parseFloat(netRate.toFixed(2));
    }

    // private
    calculateFinalPrice(qtyValue, priceAfterTaxValue) {
        if (isNaN(qtyValue) || isNaN(priceAfterTaxValue)) {
            return 0;
        } else {
            const qty = parseFloat(qtyValue);
            const priceAfterTax = parseFloat(priceAfterTaxValue);
            const finalPriceValue = qty * priceAfterTax;
            return parseFloat(finalPriceValue.toFixed(2));
        }
    }

    // private
    caculatePriceWithoutTax(mrpValue, qtyValue) {
        if (isNaN(mrpValue) || isNaN(qtyValue)) {
            return 0;
        } else {
            const mrp = parseFloat(mrpValue);
            const qty = parseFloat(qtyValue);
            const priceWithoutTax = mrp * qty;
            return parseFloat(priceWithoutTax.toFixed(2));
        }
    }

    // private
    calculateTotalAmount(
        old_total_amount,
        old_total_amount_per_product,
        new_total_amount_per_product
    ) {
        return (
            parseFloat(old_total_amount) -
            parseFloat(old_total_amount_per_product) +
            parseFloat(new_total_amount_per_product)
        );
    }

    //public
    whenAvailableQtyChange(newQty, item) {
        const gst = item.gst;
        const netRate = item.net_rate;
        const mrp = item.mrp;
        const pricePerProductWithGst = this.caculatePriceWithTax(gst, netRate);
        const totalAmountWithGst = this.calculateFinalPrice(
            newQty,
            pricePerProductWithGst
        );
        const totalAmountWithoutGst = this.totalAmountPerItemWithoutGst(
            mrp,
            newQty
        );

        return {
            ...item,
            available_qty: newQty,
            total_amount_per_item_with_gst: totalAmountWithGst,
            // total_amount_per_item_without_gst: totalAmountWithoutGst,
        };
    }

    getUpdateOrderItem(cartItems, orderAmount, orderId) {
        return {
            order_id: orderId,
            order_amount: orderAmount,
            order_items: cartItems,
            status: "waiting for admin",
        };
    }

    placedOrder(cartItems, orderAmount, orderId) {
        return {
            order_id: orderId,
            order_amount: orderAmount,
            order_items: cartItems,
            status: "order is placed",
        };
    }
}

export default Order_m;
