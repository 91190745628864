import { useEffect, useState } from "react";
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query";
import AppSection from "../../Components/AppSection/AppSection";
import styled from "styled-components";
import { getBrandFn } from "../../Api/Index";
import { ROUTES } from "../../Routes/Route";
import SkeletonCard from "../../Components/Skeleton/BrandSkeleton";

import { useDispatch } from "react-redux";
import useAxiosPrivate from "../../Api/useAxiosPrivate";

const Container = styled.div`
    width: 1500px;
    margin: auto;
    padding: 0px 22px;
    @media (max-width: 1500px) {
        width: 98%;
        padding: 0px 12px;
    }
    @media (max-width: 1050px) {
        width: 96%;
        padding: 0px 0px;
    }
    @media (max-width: 767px) {
        width: 93%;
        padding: 0px 13px;
    }
`;

const WrapperCtn = styled.div`
    display: grid;
    width: 100%;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    @media (max-width: 1050px) {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    @media (max-width: 767px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-gap: 10px;
    }
`;
const ImaContainer = styled.a`
    border: 1px solid #eaeaea;
    border-radius: 0.375rem;
`;

const Image = styled.img`
    width: 100%;
`;
const ImgName = styled.h1`
    font-size: 1rem;
    line-height: 1.5rem;
    color: #000;
    text-align: center;
    @media (max-width: 575px) {
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1px;
    }
`;
const Heading = styled.h1`
    font-size: 3rem;
    font-weight: 600;
    margin-top: 70px;
    margin-bottom: 30px;
`;

const Brands = () => {
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();

    const queryKey = "brand";
    const brand_url = `${ROUTES.getBrand}`;

    const queryConfig = {
        refetchOnWindowFocus: false,
        // staleTime: 50000,
        // cacheTime: 10000,
        keepPreviousData: true,
    };

    const {
        data: brandList,
        isLoading,
        isFetching,
        isError,
        refetch,
    } = useQuery([queryKey, brand_url, axiosPrivate], getBrandFn, queryConfig);
    const brand = brandList?.data;

    return (
        <>
            <Container>
                <Heading>Brands</Heading>
                <WrapperCtn>
                    {isLoading ? (
                        <SkeletonCard limit={20} />
                    ) : (
                        brand?.map((item, index) => (
                            <ImaContainer
                                key={index.toString()}
                                href={`/collections/${item.slug}`}
                                style={{ textDecoration: "none" }}
                                rel="noreferrer"
                            >
                                <div>
                                    <Image
                                        src={item.image.desktop.url}
                                        quality="100"
                                        alt={item.brand_name}
                                    />
                                </div>
                                <div>
                                    <ImgName>{item.brand_name}</ImgName>
                                </div>
                            </ImaContainer>
                        ))
                    )}
                </WrapperCtn>
            </Container>
            <AppSection />
        </>
    );
};

export default Brands;
