import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import {
    searchParams,
    useSearchParams,
    useNavigate,
    useLocation,
} from "react-router-dom";
import qs from "qs";
import Checkbox from "@mui/material/Checkbox";
import { pink } from "@mui/material/colors";

import { sizeHeight } from "@mui/system";
import styled from "styled-components";

const FilterArea = styled.div`
    padding: 8px 0px;
`;
const Wrapper = styled.div`
    margin: 0;
    border: 0 solid #e5e7eb;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(221, 221, 221);
`;
const Heading = styled.h1`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-family: Heebo, sans-serif;
    color: #282c3f;
`;
const Input = styled.input``;
const Span = styled.span`
    margin-left: 14px;
    font-size: 13px;
    color: #282c3f;
`;
const Spantext = styled.div`
    font-size: 12px;
    font-weight: bold;
    color: #4d4a4a;
    margin: 5px 0px;
`;
const Label = styled.label`
    display: flex;
    padding-bottom: 5px;
    cursor: pointer;
`;

const Filter = ({ filter_name, options, filter_key }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const selectedOptions = searchParams.get(filter_key)
        ? searchParams.get(filter_key).split(",")
        : [];

    const [formState, setFormState] = useState(selectedOptions);

    useEffect(() => {
        setFormState(selectedOptions);
    }, [searchParams.get(filter_key)]);

    const handleItemClick = (e) => {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: "smooth" });
        const { value } = e.currentTarget;
        const currentFormState = formState.includes(value)
            ? formState.filter((i) => i !== value)
            : [...formState, value];
        const existingQueries = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        const newQueryString = qs.stringify(
            {
                ...existingQueries,
                ["page"]: 1,
                [filter_key]:
                    currentFormState.length > 0
                        ? currentFormState.join(",")
                        : {},
            },
            { skipNull: true }
        );
        navigate(`${location.pathname}?${newQueryString}`);
    };

    return (
        <>
            <FilterArea>
                <Wrapper>
                    <Heading>{filter_name}</Heading>
                    {options?.map((item, index) => {
                        return (
                            <Label key={index.toString()}>
                                <Checkbox
                                    key={index.toString()}
                                    value={`${item.value}`}
                                    checked={formState.includes(
                                        `${item.value}`
                                    )}
                                    onChange={handleItemClick}
                                    inputProps={{ "aria-label": "controlled" }}
                                    sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 15 },
                                        color: "#bbb",
                                        "&.Mui-checked": { color: "#FF6E31" },
                                        padding: 0,
                                    }}
                                />
                                <Span>{item.label}</Span>
                            </Label>
                        );
                    })}
                    <Spantext>See More</Spantext>
                </Wrapper>
            </FilterArea>
        </>
    );
};

export default Filter;
