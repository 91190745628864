import { useDispatch, useSelector } from "react-redux";
import {
    useParams,
    useSearchParams,
    useLocation,
    useNavigate,
    useMatch,
} from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import * as Realm from "realm-web";
import jwt_decode from "jwt-decode";
import { Grid, Button } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Card, Chip, IconButton } from "@mui/material";

import styled from "styled-components";
import Topbar from "../../../Components/Topbar/Topbar";
import Menubar from "../../../Components/Menubar/Menubar";
import Navbar from "../../../Components/Navbar/Navbar";
import Footer from "../../../Components/Layout/Footer";
import Nav from "../Nav";
import AccountLayout from "../../../Components/MyAccount/AccountLayout";

import { ROUTES } from "../../../Routes/Route";
import { getOrderFn } from "../../../Api/Index";
import useAxiosPrivate from "../../../Api/useAxiosPrivate";
import OrderPagination from "./PaginationContainer";

import { UI } from "../../../Constants/Index";
import Order_m from "../../../Models/Order_m";

import { addOrderItemToCartToUpdate } from "../../../Store/Action/Index";

const Container = styled.div`
    margin: 0px auto;
    margin-top: ${parseInt(UI.topBarHeight) + parseInt(UI.navBarHeight)}px;
    width: 1349px;
    @media (max-width: 1050px) {
        width: 98%;
    }
`;
const Wrapper = styled.div`
    display: flex;
`;
const Main = styled.div`
    flex: 6 1 0%;
`;

const Form = styled.form``;

const Filters = styled.div`
    display: flex;
    padding: 1px 11px;
    margin: 1px 0px;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    &:last-child {
        border-bottom: none;
    }
    @media (max-width: 642px) {
        display: block;
        padding: 12px 16px;
        margin-bottom: 22px;
        border-radius: 3px;
        box-shadow: 0px 0px 8px #dbdbdb;
    }
`;

const Textarea = styled.div`
    display: flex;
    flex: 5;
    font-family: initial;
    align-items: center;
    @media (max-width: 870px) {
        font-size: 13px;
    }
    @media (max-width: 642px) {
        display: block;
        font-size: 14px;
        width: 100%;
    }
`;

const Srno = styled.div`
    font-size: 12px;
    padding: 0;
    width: 40px;
    @media (max-width: 642px) {
        margin-left: 0px;
    }
`;

const Headingtext = styled.div`
    font-size: 13px;
    padding: 0 10px;
    margin-left: 6px;
    min-width: 100px;
    @media (max-width: 642px) {
        margin-left: 0px;
    }
`;

const Description = styled.div`
    margin-left: 6px;
    padding: 0 10px;
    font-size: 13px;
    @media (max-width: 642px) {
        margin-left: 0px;
    }
`;

const CartButton = styled.div`
    display: flex;
    flex: 3;
    justify-content: flex-end;
    @media (max-width: 642px) {
        width: 100%;
    }
`;

const Div = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const Rupee = styled.div`
    margin-left: 6px;
    padding: 0 10px;
    font-size: 12px;
    @media (max-width: 642px) {
        width: 100%;
        margin-left: 0px;
        padding: 3px 10px;
    }
`;

const appId = "vishwa-sports-b2b-jdupr";
const appConfig = {
    id: appId,
    timeout: 10000,
};

const RealmInstance = new Realm.App(appConfig);

const Order = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    let [searchParams, setSearchParams] = useSearchParams();

    console.log("user id ", RealmInstance?.currentUser?.customData);
    const jwt_token = localStorage.getItem("accessTokenValue");

    var decoded_jwt = jwt_decode(jwt_token);
    const user_id = decoded_jwt.sub;

    const orderQueryKey = "order";
    const order_url = `${ROUTES.getCustomerOrder}`;
    let currentPage = searchParams.get("page") ? searchParams.get("page") : 1;

    const queryConfig = {
        refetchOnWindowFocus: false,
        // staleTime: 50000,
        // cacheTime: 10000,
        keepPreviousData: true,
    };

    function getOrderQuery() {
        const initialQuery = location.search
            ? `${location.search}`
            : `${location.search}`;

        const str = location.search ? initialQuery.substring(1) : initialQuery;

        const qt = qs.parse(str, { skipNull: true });

        for (const key in qt) {
            qt[key] = { $in: qt[key].split(",") };
        }

        qt.page = currentPage;

        const stringifiedQuery = qs.stringify(qt, { skipNull: true });

        const order_url = `${ROUTES.getCustomerOrder}?customer_id=${user_id}&${stringifiedQuery}`;

        return order_url;
    }

    const final_order_url = getOrderQuery();

    console.log("final_order_url ");
    console.log(final_order_url);

    const {
        data: orderList,
        isLoading: isOrderLoading,
        isFetching: isOrderFetching,
        isError: isOrderError,
        refetch,
    } = useQuery(
        [orderQueryKey, final_order_url, axiosPrivate],
        getOrderFn,
        queryConfig
    );
    const orderObj = orderList?.data;

    const perPage = orderList?.perPage ? parseInt(orderList?.perPage) : 20;
    const totalOrder = orderList?.totalCount
        ? parseInt(orderList?.totalCount)
        : 21;
    const lastPage = Math.ceil(totalOrder / perPage);

    const viewOrderDetail = (item) => {
        dispatch(addOrderItemToCartToUpdate(item));
        navigate(`/orderDetail/${item._id}`);
    };

    const OrderCard = ({ order, isLoading }) => {
        return order?.map((item, idx) => {
            const orderObj = new Order_m(
                item._id,
                item?.customer_id,
                item?.created_by,
                item?.first_name,
                item?.last_name,
                item?.customer_email,
                item?.customer_whatsapp_no,
                item?.order_amount,
                item?.status,
                item?.order_date,
                item?.last_updated_on
            );

            return (
                <Filters>
                    <Textarea>
                        <Srno>{idx + 1}</Srno>
                        <Headingtext>{item?.order_amount}</Headingtext>
                        <Description>
                            <Chip
                                variant="combined"
                                color={
                                    item.status == "waiting for admin"
                                        ? "warning"
                                        : item.status == "order is placed"
                                        ? "success"
                                        : "primary"
                                }
                                label={`${item.status}`}
                                size="small"
                            />
                        </Description>
                    </Textarea>
                    <CartButton>
                        <Div>
                            <Rupee>{item?.order_date}</Rupee>
                        </Div>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={() => viewOrderDetail(item)}
                        >
                            View Items
                        </Button>
                    </CartButton>
                </Filters>
            );
        });
    };

    const changePage = (page) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        const existingQueries = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        const newQueryString = qs.stringify(
            { ...existingQueries, ["page"]: page },
            { skipNull: true }
        );
        navigate(`${location.pathname}?${newQueryString}`);
    };

    return (
        <>
            <Navbar start={false} />
            <Container>
                <Wrapper>
                    <AccountLayout>
                        <Nav />
                        <Main>
                            <Form>
                                <Filters
                                    style={{
                                        position: "sticky",
                                        top: 115,
                                        background: "rgb(240, 240, 240)",
                                        zIndex: 10,
                                        paddingTop: 7,
                                        paddingBottom: 7,
                                    }}
                                >
                                    <Textarea>
                                        <Srno>Sr. No.</Srno>
                                        <Headingtext>Total Amount</Headingtext>
                                        <Description>Status</Description>
                                    </Textarea>
                                    <CartButton>
                                        <Div>
                                            <Rupee>Created On</Rupee>
                                        </Div>
                                        <Div>
                                            <Rupee>Action </Rupee>
                                        </Div>
                                        {/* <Div>
                                            <Rupee>Total</Rupee>
                                        </Div> */}
                                        <IconButton
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                marginLeft: "5px",
                                                marginRight: "5px",
                                                color: "primary",
                                            }}
                                        >
                                            {/* <DeleteOutlineIcon /> */}
                                        </IconButton>
                                        {/* <Div> */}

                                        {/* </Div> */}
                                    </CartButton>
                                </Filters>
                                <OrderCard
                                    isLoading={isOrderFetching}
                                    order={orderObj}
                                />

                                {isOrderFetching == true &&
                                orderObj === undefined ? null : (
                                    <OrderPagination
                                        currentPageNo={currentPage}
                                        totalCount={totalOrder}
                                        pageSize={perPage}
                                        totalPage={lastPage}
                                        onPageChange={(page) =>
                                            changePage(page)
                                        }
                                    />
                                )}
                            </Form>
                        </Main>
                    </AccountLayout>
                </Wrapper>
            </Container>
            <Footer />
        </>
    );
};
export default Order;
