import React, { useState, useEffect } from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    useParams,
    useLocation,
    Outlet,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Realm from "realm-web";
import qs from "qs";

import { createStore, applyMiddleware } from "redux";
import { Provider, useDispatch, useSelector } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { composeWithDevToolsDevelopmentOnly } from "@redux-devtools/extension";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider, useSnackbar } from "notistack";

import Menubar from "../src/Components/Menubar/Menubar";
import Home from "./pages/Home/Home";
import PriceList from "./pages/PriceList/PriceList";
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/Register";
import Catalogue from "./pages/Catalogue/Catalogue";
import Privacy from "./pages/Privacy/Privacy";
import ProductList from "./pages/Product/ProductList";
import Brands from "./pages/Brands/Brands";
import Search from "./pages/Search/Search";
import Collections from "./pages/Collection/Collections";
import Emptycart from "./pages/Cart/Emptycart";
import Cart from "./pages/Cart/Cart";
import Checkout from "./pages/Checkout/Checkout";
import Contact from "./pages/Contactus/Contact";
import About from "./pages/Aboutus/About";
import Disclaimer from "./pages/Disclaimer/Disclaimer";
import Faq from "./pages/Faq/Faq";
import TermsandConditions from "./pages/Terms & Conditions/TermsandConditions";
import Filter from "./pages/Search/Filter";
// import Order from "./pages/Myaccount/Order";
import Profile from "./pages/Myaccount/Profile/Profile";

import Dashboard from "./pages/Myaccount/Dashboard/Dashboard";
import Order from "./pages/Myaccount/Order/Order";
import OrderDetail from "./pages/Myaccount/Order/OrderDetail";

// import Ac from "./pages/Myaccount/Ac";

// test branch
import rootReducer from "./Store/Reducer/Index";

import { Colors } from "./Constants/Index";

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = createStore(
    persistedReducer,
    composeWithDevToolsDevelopmentOnly(applyMiddleware(thunk))
);
let persistor = persistStore(store);

const appId = "vishwa-sports-b2b-jdupr";
const appConfig = {
    id: appId,
    timeout: 10000,
};

const RealmInstance = new Realm.App(appConfig);

function Vishwa() {
    const queryClient = new QueryClient();

    // window.addEventListener("beforeunload", (event) => {
    //     window.localStorage.isMySessionActive = "true";
    // });
    // window.onunload = function (e) {
    //     const newTabCount = localStorage.getItem("tabsOpen");
    //     if (newTabCount !== null) {
    //         localStorage.setItem("tabsOpen", newTabCount - 1);
    //     }
    // };

    const logOut = () => {
        console.log("successfully logout");
    };

    // useEffect(() => {
    //     // define increment counter part
    //     const tabsOpen = localStorage.getItem("tabsOpen");
    //     if (tabsOpen == null) {
    //         localStorage.setItem("tabsOpen", 1);
    //     } else {
    //         localStorage.setItem("tabsOpen", parseInt(tabsOpen) + parseInt(1));
    //     }

    //     // define decrement counter part
    //     window.onunload = function (e) {
    //         const newTabCount = localStorage.getItem("tabsOpen");
    //         if (newTabCount !== null) {
    //             localStorage.setItem("tabsOpen", newTabCount - 1);
    //         }
    //     };
    //     if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
    //         window.localStorage.isMySessionActive = "false";
    //     } else {
    //         const newTabCount2 = localStorage.getItem("tabsOpen");
    //         let value = localStorage.getItem("isMySessionActive");
    //         if (value == "true") {
    //             if (newTabCount2 - 1 == 0) {
    //                 localStorage.clear();
    //                 console.log("successfully logout");
    //                 RealmInstance?.currentUser?.logOut();
    //                 window.localStorage.isMySessionActive = "false";
    //             } else {
    //                 window.localStorage.isMySessionActive = "false";
    //             }
    //         }
    //     }
    // }, []);

    const theme = createTheme({
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: Colors.primary,
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            secondary: {
                light: "#0066ff",
                main: "#0044ff",
                // dark: will be calculated from palette.secondary.main,
                contrastText: "#ffcc00",
            },
            // Provide every color token (light, main, dark, and contrastText) when using
            // custom colors for props in Material UI's components.
            // Then you will be able to use it like this: `<Button color="custom">`
            // (For TypeScript, you need to add module augmentation for the `custom` value)
            custom: {
                light: "#ffa726",
                main: "#f57c00",
                dark: "#ef6c00",
                contrastText: "rgba(0, 0, 0, 0.87)",
            },
            // Used by `getContrastText()` to maximize the contrast between
            // the background and the text.
            contrastThreshold: 3,
            // Used by the functions below to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
                        <PersistGate loading={null} persistor={persistor}>
                            <BrowserRouter>
                                <Routes>
                                    <Route index path="/" element={<Home />} />

                                    <Route
                                        index
                                        path="/menubar"
                                        element={<Menubar />}
                                    />
                                    <Route
                                        index
                                        path="/login"
                                        element={<Login />}
                                    />
                                    <Route
                                        index
                                        path="/register"
                                        element={<Register />}
                                    />
                                    <Route
                                        path="collections"
                                        element={<Outlet />}
                                    >
                                        <Route
                                            index
                                            element={<Collections />}
                                        />
                                        <Route
                                            path="/collections/*"
                                            element={<Search />}
                                        />
                                    </Route>
                                    <Route
                                        path="/pricelist"
                                        element={<PriceList />}
                                    />
                                    <Route
                                        path="/catalogue"
                                        element={<Catalogue />}
                                    />
                                    <Route
                                        path="/privacy"
                                        element={<Privacy />}
                                    />
                                    <Route
                                        path="/Product"
                                        element={<ProductList />}
                                    />
                                    <Route
                                        path="/brands"
                                        element={<Brands />}
                                    />
                                    <Route
                                        path="/emptycart"
                                        element={<Emptycart />}
                                    />
                                    <Route path="/cart" element={<Cart />} />
                                    <Route
                                        path="/checkout"
                                        element={<Checkout />}
                                    />

                                    <Route
                                        path="/search"
                                        element={<Search />}
                                    />
                                    <Route
                                        path="/contact"
                                        element={<Contact />}
                                    />
                                    <Route path="/about" element={<About />} />
                                    <Route
                                        path="/disclaimer"
                                        element={<Disclaimer />}
                                    />
                                    <Route path="/faq" element={<Faq />} />
                                    <Route
                                        path="/termsandConditions"
                                        element={<TermsandConditions />}
                                    />
                                    <Route
                                        path="/filter"
                                        element={<Filter />}
                                    />

                                    <Route
                                        path="/profile"
                                        element={<Profile />}
                                    />

                                    <Route path="/order" element={<Order />} />
                                    <Route
                                        path="/orderDetail/:id"
                                        element={<OrderDetail />}
                                    />

                                    {/* <Route path="/ac" element={<Ac />} /> */}
                                </Routes>
                            </BrowserRouter>
                        </PersistGate>
                    </SnackbarProvider>
                </QueryClientProvider>
            </Provider>
        </ThemeProvider>
    );
}

export default Vishwa;
