import { Link } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";

import down from "../../Components/Images/down.png";
import right from "../../Components/Images/right.png";

import useAxiosPrivate from "../../Api/useAxiosPrivate";
import { getCategoryFn } from "../../Api/Index";
import { ROUTES } from "../../Routes/Route";

const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`
    flex: 1.5;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 996px) {
        display: flex;
        justify-content: center;
    }
`;

const Ul = styled.ul`
    width: 100%;
    display: flex;
    justify-content: flex-start;
`;

const Span = styled.div`
    padding: 18px 15px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1076px) {
        padding: 18px 12px;
    }
`;

const Text = styled.div`
    margin-right: 8px;
    font-size: 14px;
    @media (max-width: 1076px) {
        font-size: 13px;
    }
`;

const Downicon = styled.div`
    display: block;
    width: 9px;
    height: 25px;
`;

const Image = styled.img`
    width: 100%;
`;

const Li = styled.div`
    padding: 4px 12px;
    width: 150px;
    font-size: 14px;
    color: #7a7373;
    display: flex;
    letter-spacing: 1.2px;
    justify-content: space-between;
    &:hover {
        color: black;
    }
`;

const Innertext = styled.div`
    width: 150px;
    display: flex;
    justify-content: space-between;
`;

const InnerText = styled.div`
    margin: 0px;
`;

const LiAnchor = styled.div`
    border-bottom: 1px solid white;
    color: #3c3b3b;
    &:hover {
        border-bottom: 1px solid gray;
        color: black;
    }
`;

const Righticon = styled.div`
    width: 9px;
    height: 15px;
    display: ${(props) => (props.clicked ? "none" : "block")};
`;

const Anchor = styled.a`
    width: 124px;
    height: 18px;
    display: block;
`;

const Center = () => {
    const queryKey = "category";
    const category_url = `${ROUTES.getCategory}`;
    const axiosPrivate = useAxiosPrivate();

    const {
        data: categoryList,
        isLoading,
        isFetching,
        isError,
        refetch,
    } = useQuery([queryKey, category_url, axiosPrivate], getCategoryFn, {
        refetchOnWindowFocus: false,
        staleTime: 30 * (60 * 1000),
        cacheTime: 120 * (60 * 1000),
    });

    const category_lists = categoryList?.data;

    const MenuList = () => {
        return (
            <Ul>
                {isLoading
                    ? null
                    : category_lists?.map((item, idx) => {
                          return (
                              <div className="dropdown" key={item.slug}>
                                  <Link
                                      key={item.slug}
                                      to={
                                          item.isCollection
                                              ? `/collections/${item.slug}`
                                              : null
                                      }
                                  >
                                      <Span>
                                          <Text>{item.category_name}</Text>
                                          <Downicon className="down">
                                              <Image src={down} />
                                          </Downicon>
                                      </Span>
                                  </Link>

                                  <div className="dropdown-content">
                                      {item?.sub_category?.map(
                                          (item2, idx2) => {
                                              return item2.types ? (
                                                  <div key={item2.slug}>
                                                      <Li>
                                                          <div className="inner">
                                                              <Innertext>
                                                                  <LiAnchor>
                                                                      <InnerText>
                                                                          {
                                                                              item2.sub_category_name
                                                                          }
                                                                      </InnerText>
                                                                  </LiAnchor>
                                                                  <Righticon>
                                                                      <Image
                                                                          src={
                                                                              right
                                                                          }
                                                                      />
                                                                  </Righticon>
                                                              </Innertext>
                                                              <div className="inner-content">
                                                                  {item2?.types?.map(
                                                                      (
                                                                          item3,
                                                                          idx3
                                                                      ) => {
                                                                          return (
                                                                              <Link
                                                                                  key={
                                                                                      item3.slug
                                                                                  }
                                                                                  to={
                                                                                      item3.isCollection
                                                                                          ? `/collections/${item3.slug}`
                                                                                          : null
                                                                                  }
                                                                              >
                                                                                  <Li>
                                                                                      <LiAnchor>
                                                                                          {
                                                                                              item3.type_name
                                                                                          }
                                                                                      </LiAnchor>
                                                                                  </Li>
                                                                              </Link>
                                                                          );
                                                                      }
                                                                  )}
                                                              </div>
                                                          </div>
                                                      </Li>
                                                  </div>
                                              ) : (
                                                  <Link
                                                      key={item2.slug}
                                                      to={
                                                          item2.isCollection
                                                              ? `/collections/${item2.slug}`
                                                              : null
                                                      }
                                                  >
                                                      <Li>
                                                          <LiAnchor>
                                                              {
                                                                  item2.sub_category_name
                                                              }
                                                          </LiAnchor>
                                                      </Li>
                                                  </Link>
                                              );
                                          }
                                      )}
                                  </div>
                              </div>
                          );
                      })}
            </Ul>
        );
    };

    return (
        <Container>
            <MenuList />
        </Container>
    );
};

const logo = "https://vishwasports.s3.ap-south-1.amazonaws.com/gravity.png";
const CompanyLogo = () => {
    return (
        <Container>
            <Anchor href="/">
                <Image src={logo} alt="Vishwa Sports" />
            </Anchor>
        </Container>
    );
};

export default width > 996 ? Center : CompanyLogo;
