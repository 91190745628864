import styled from "styled-components";
import { Link } from "react-router-dom";

const FooterLink = styled.div`
	width: 100%;
`;

const ListHeading = styled.div`
	color: #fff;
`;
const Ul = styled.div`
	@media (max-width: 767px) {
		margin-bottom: 25px;
	}
`;
const FooterUp = (props) => {
	return (
		<FooterLink>
			<ListHeading>
				<h5 className="footerLink">{props.data.widgetTitle}</h5>
				<Ul>
					{props.data.lists.map((list) => (
						<li key={`widget-list--key${list.id}`}>
							{list.icon && (
								<span className="svgIcon">{list.icon}</span>
							)}
							<Link to={list.path}>{list.title}</Link>
						</li>
					))}
				</Ul>
			</ListHeading>
		</FooterLink>
	);
};

export default FooterUp;
