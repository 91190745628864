import styled from "styled-components";
import Mobomenu from "../../Components/Menubar/Mobomenu";
import Left from "./Left";
import Center from "./Center";
import Right from "./Right";

const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`
    height: 80px;
    border-bottom: 1px solid #eaeaea;
    @media (max-width: 996px) {
        height: 60px;
    }
`;
const Wrapper = styled.div`
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
`;

const Menu = () => {
    return (
        <Container>
            {width > 767 ? (
                <Wrapper>
                    <Left />
                    <Center />
                    <Right />
                </Wrapper>
            ) : (
                <Mobomenu />
            )}
        </Container>
    );
};

export default Menu;
