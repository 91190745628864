import { combineReducers } from "redux";

import AuthReducer from "./Auth/AuthReducer";
import CartReducer from "./Cart/CartReducer";
import OrderReducer from "./Order/OrderReducer";

export default combineReducers({
    Auth: AuthReducer,
    Cart: CartReducer,
    Order: OrderReducer,
});
