import {
    useParams,
    useSearchParams,
    useLocation,
    useNavigate,
    useMatch,
} from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";

import Announcement from "../../Components/Announcement/Announcement";
import Filtermenu from "../../Components/Announcement/Filtermenu";
import Topbar from "../../Components/Topbar/Topbar";
import Menubar from "../../Components/Menubar/Menubar";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Layout/Footer";

import Filter from "../../Components/Filter/Filter";

import Products from "./Products";
import styled from "styled-components";

import { getCollectionFn, getProductFn } from "../../Api/Index";
import { ROUTES } from "../../Routes/Route";
import ProductPagination from "./PaginationContainer";

import useAxiosPrivate from "../../Api/useAxiosPrivate";

import { UI } from "../../Constants/Index";

const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`
    width: 100%;
    margin: 0px auto;
    height: auto;
    margin-top: ${parseInt(UI.topBarHeight) + parseInt(UI.navBarHeight)}px;
    @media (max-width: 996px) {
        margin-top: ${parseInt(UI.topBarHeight) +
        parseInt(UI.navBarMoboHeight)}px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    background: #f4f4f4;
    justify-content: space-between;
`;

const Filtercount = styled.div`
    width: 17%;
    margin: 6px 4px;
    background: white;
    padding: 18px 13px;
    border-radius: 4px;
    @media (max-width: 1050px) {
        width: 21%;
    }
`;

const Searcharea = styled.div`
    width: 78%;
    background: white;
    margin: 6px 4px;
    background: white;
    padding: 18px;
    border-radius: 4px;
    @media (max-width: 996px) {
        width: 100%;
        padding: 0;
    }
`;

const Select = styled.select`
    padding: 8px;
    width: 100%;
    margin-top: 2px;
    font-size: 15px;
    font-weight: 300;
    border: none;
    border-radius: 6px;
    background-color: rgb(247, 247, 247);
`;

const Option = styled.option``;
const Label = styled.label``;

const Filtertext = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`;

const Text = styled.div`
    color: rgb(104, 104, 104);
    font-size: 12px;
`;

const FilterCtn = styled.div`
    border-bottom: 1px solid rgb(221, 221, 221);
`;

const Space = styled.div``;

const TopFilter = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const Mid = styled.div`
    font-size: 14px;
    margin: 0px 10px;
    display: flex;
    align-items: end;
    color: rgb(138, 134, 134);
`;
const Headingtop = styled.div`
    font-size: 23px;
    font-weight: 400;
`;

const Search = () => {
    const axiosPrivate = useAxiosPrivate();
    let [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    console.log("params ");

    console.log(
        location.pathname
            .slice(location.pathname.lastIndexOf("/"), location.pathname.length)
            .split("/")
    );

    const queryParams = params["*"].split("/");

    const lastItem = queryParams.slice(-1);

    console.log(lastItem);

    const collectionString = qs.stringify(
        {
            ["slug"]: lastItem.join(","),
        },
        { skipNull: true }
    );

    let currentPage = searchParams.get("page") ? searchParams.get("page") : 1;

    const prodString = qs.stringify(
        {
            ["collection"]: lastItem.join(","),
        },
        { skipNull: true }
    );

    const queryKey = "collection";
    const collection_url = `${ROUTES.getCollection}?${collectionString}`;

    const queryConfig = {
        refetchOnWindowFocus: false,
        // staleTime: 50000,
        // cacheTime: 10000,
        keepPreviousData: true,
    };

    const { data: filterList } = useQuery(
        [queryKey, collection_url, axiosPrivate],
        getCollectionFn,
        queryConfig
    );
    const collection_filter = filterList?.data?.collection_filter;

    const productQueryKey = "product";
    function getProductQuery() {
        const initialQuery = location.search
            ? `${location.search}&${prodString}`
            : `${prodString}`;

        const str = location.search ? initialQuery.substring(1) : initialQuery;

        const qt = qs.parse(str, { skipNull: true });

        for (const key in qt) {
            qt[key] = { $in: qt[key].split(",") };
        }

        qt.page = currentPage;

        const stringifiedQuery = qs.stringify(qt, { skipNull: true });

        const product_url = `${ROUTES.getProduct}?${stringifiedQuery}`;
        return product_url;
    }

    const changePage = (page) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        const existingQueries = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });
        const newQueryString = qs.stringify(
            { ...existingQueries, ["page"]: page },
            { skipNull: true }
        );
        navigate(`${location.pathname}?${newQueryString}`);
    };

    const final_product_url = getProductQuery();

    const {
        data: productList,
        isLoading: isProductLoading,
        isFetching: isProductFetching,
        isError: isProductError,
        refetch,
    } = useQuery(
        [productQueryKey, final_product_url, axiosPrivate],
        getProductFn,
        queryConfig
    );
    const productObj = productList?.data;

    const perPage = productList?.perPage ? parseInt(productList?.perPage) : 20;
    const totalProduct = productList?.totalCount
        ? parseInt(productList?.totalCount)
        : 21;
    const lastPage = Math.ceil(totalProduct / perPage);

    return (
        <>
            <Navbar start={false} />
            <Container>
                {width > 996 ? null : <Filtermenu />}
                <Wrapper>
                    {width > 996 ? (
                        <Filtercount>
                            <TopFilter>
                                <Headingtop>Filter</Headingtop>
                                <Text>RESET</Text>
                            </TopFilter>

                            {collection_filter?.map((item, idx) => {
                                return (
                                    <Filter
                                        key={idx.toString()}
                                        filter_name={item.filter_name}
                                        options={item.options}
                                        filter_key={item.filter_key}
                                    />
                                );
                            })}
                        </Filtercount>
                    ) : null}

                    <Searcharea>
                        <Products
                            isLoading={isProductFetching}
                            product={productObj}
                        />

                        {isProductFetching == true &&
                        productObj === undefined ? null : (
                            <ProductPagination
                                currentPageNo={currentPage}
                                totalCount={totalProduct}
                                pageSize={perPage}
                                totalPage={lastPage}
                                onPageChange={(page) => changePage(page)}
                            />
                        )}
                    </Searcharea>
                </Wrapper>
            </Container>
            <Footer />
        </>
    );
};

export default Search;
