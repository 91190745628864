import { useEffect  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import styled from "styled-components"
import Menubar from '../../Components/Menubar/Menubar'
import Footer from '../../Components/Layout/Footer'
import { FilterSharp } from '@mui/icons-material';
import Topbar from '../../Components/Topbar/Topbar'

const Container = styled.div`
width:1050px;
margin:22px auto;
@media(max-width:1050px)
{
  width:98%;
}
`
const Wrapper = styled.div`
width: 80vw;
max-width: 1050px;
margin: auto;
border: 0px solid rgb(0, 0, 0);
`
const WrapperCtn = styled.div`
border: 0px solid rgb(0, 0, 0);
margin-top: 50px;
`
const TopHeading = styled.div`
text-align: center;
font-size: 20px;
font-weight: 600;
letter-spacing: 3px;
color: rgb(119, 119, 119);
line-height: 20px;
margin-bottom: 5px;
`
const Anchorpara = styled.a`
text-align: center;
font-size: 14px;
margin-top: 6px;
color: rgb(119, 119, 119);
line-height: 13px;
letter-spacing: 1px;
display: block;

`
const Section = styled.div`
border: 0px solid rgb(0, 0, 0);
margin: 70px 0px;
@media(max-width:767px)
{
  margin: 32px 0px;
}
`

const ItemSection = styled.div`
display: flex;
background: rgb(241, 243, 246);
justify-content: center;
box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 2px 0px;
padding: 84px 0px;
@media(max-width:767px){
  padding: 34px 0px;
}
`
const ItemImage = styled.div`
display: flex;
flex-direction: column;
align-items: center;

`
const Image = styled.img`
width: 200px;
`
const Text = styled.div`
font-size: 20px;
margin-top: 17px;
text-align: center;
margin-bottom: 6px;
`
const ViewStore = styled.a`

`
const Cart  = () => {

    return (
      <>   
      
    <Topbar/>
    <Menubar />
    <Container>
        
        <Wrapper>
     
              <WrapperCtn>
                    
                  <TopHeading>Cart</TopHeading>
                  <Anchorpara href="/searchold">Continue Shopping</Anchorpara>
              
                  <Section> 
                    <ItemSection>
                        <ItemImage>
                            <Image src="https://kiaasaretail.s3.ap-south-1.amazonaws.com/emptycart.webp"/>
                            <Text>Missing Cart items?</Text>
                            <ViewStore href="/">View Store</ViewStore>
                        </ItemImage>
                    </ItemSection>
                  </Section>

              </WrapperCtn>
                    
                  

        </Wrapper>

    </Container>
      
 
    <Footer/>
      </>
  )

}

export default Cart;

