import styled from "styled-components";

import Visa from "../../Assets/payment/visa.svg";
import Jcb from "../../Assets/payment/jcb.svg";
import MasterCard from "../../Assets/payment/mastercard.svg";
import Skrill from "../../Assets/payment/skrill.svg";
import Paypal from "../../Assets/payment/paypal.svg";

const Container = styled.div`
    width: 1050px;
    @media (max-width: 1050px) {
        width: 96%;
    }
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 6px 0px;
    justify-content: space-between;
    @media (max-width: 1050px) {
        display: block;
    }
`;

const FooterUp = () => {
    return (
        <Container className="copyright">
            <Wrapper>
                <p className="copyrightText">
                    Copyright © 2023-2024&nbsp;
                    <span>Vishwa Sports</span>
                    &nbsp; All rights reserved
                </p>

                <ul className="paymentLink">
                    <li className="mb-2 md:mb-0 transition hover:opacity-80">
                        <a href="/" target="_blank">
                            <img
                                src={MasterCard}
                                alt="Master Card"
                                width="34"
                                height="20"
                            />
                        </a>
                    </li>
                    <li className="mb-2 md:mb-0 transition hover:opacity-80">
                        <a href="/" target="_blank">
                            <img src={Visa} alt="Visa" width="50" height="20" />
                        </a>
                    </li>
                    <li className="mb-2 md:mb-0 transition hover:opacity-80">
                        <a href="/" target="_blank">
                            <img
                                src={Paypal}
                                alt="Paypal"
                                width="76"
                                height="20"
                            />
                        </a>
                    </li>
                    <li className="mb-2 md:mb-0 transition hover:opacity-80">
                        <a href="/" target="_blank">
                            <img src={Jcb} alt="JCB" width="26" height="20" />
                        </a>
                    </li>
                    <li className="mb-2 md:mb-0 transition hover:opacity-80">
                        <a href="/" target="_blank">
                            <img
                                src={Skrill}
                                alt="Skrill"
                                width="39"
                                height="20"
                            />
                        </a>
                    </li>
                </ul>
            </Wrapper>
        </Container>
    );
};

export default FooterUp;
