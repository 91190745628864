export const SET_LOGGED_USER = "SET_LOGGED_USER";
export const LOGOUT_USER = "LOGOUT_USER";

export const setLoggedUser = (user) => {
    return {
        type: SET_LOGGED_USER,
        payload: user,
    };
};

export const logoutUser = () => {
    return {
        type: LOGOUT_USER,
    };
};
