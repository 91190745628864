import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import styled from "styled-components";
import LogoutIcon from "@mui/icons-material/Logout";
import {
    getAuth,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    onAuthStateChanged,
} from "firebase/auth";
import * as Realm from "realm-web";
import { signOut } from "firebase/auth";

import { logoutUser } from "../../Store/Action/Index";
import app from "../../Config/Firebase";

const Nav = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    height: 200px;
    gap: 10px;
    padding: 10px;
    position: sticky;
    top: 115px;
`;
const InneraLink = styled.div`
    cursor: pointer;
`;
const Span = styled.div`
    font-size: 13px;
    margin-left: 3px;
`;
const Navctn = styled.div`
    display: flex;
`;

const appId = "vishwa-sports-b2b-jdupr";
const appConfig = {
    id: appId,
    timeout: 10000,
};

const auth = getAuth(app);
const RealmInstance = new Realm.App(appConfig);

const Myaccount = () => {
    const dispatch = useDispatch();

    const AUTH = useSelector((state) => state.Auth);
    const isAuthorised = AUTH.isAuthorised;
    const currentRealmUser = RealmInstance.currentUser;

    async function realmLogout() {
        const logout = await currentRealmUser?.logOut();
        console.log("logout ", currentRealmUser?.id);
        dispatch(logoutUser());
        window.location.replace("/");
    }

    console.log("currentRealmUser ", currentRealmUser?.id);

    const handleLogout = () => {
        signOut(auth)
            .then((res) => {
                localStorage.clear();
                realmLogout();
            })
            .catch((error) => {
                console.log("error");
                console.log(error);
            });
    };

    return (
        <>
            <Nav>
                <InneraLink>
                    <Link to="/order">
                        <Navctn>
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                className="w-5 h-5"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle
                                    cx="176"
                                    cy="416"
                                    r="16"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="32"
                                ></circle>
                                <circle
                                    cx="400"
                                    cy="416"
                                    r="16"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="32"
                                ></circle>
                                <path
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="32"
                                    d="M48 80h64l48 272h256"
                                ></path>
                                <path
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="32"
                                    d="M160 288h249.44a8 8 0 007.85-6.43l28.8-144a8 8 0 00-7.85-9.57H128"
                                ></path>
                            </svg>
                            <Span>Orders</Span>
                        </Navctn>
                    </Link>
                </InneraLink>

                <InneraLink>
                    <Link to="/profile">
                        <Navctn>
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 512 512"
                                className="w-5 h-5"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="32"
                                    d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z"
                                ></path>
                                <path
                                    fill="none"
                                    strokeMiterlimit="10"
                                    strokeWidth="32"
                                    d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                                ></path>
                            </svg>
                            <Span>Profile</Span>
                        </Navctn>
                    </Link>
                </InneraLink>

                <InneraLink onClick={handleLogout}>
                    <Navctn>
                        <LogoutIcon fontSize="10" />
                        <Span>Logout</Span>
                    </Navctn>
                </InneraLink>
            </Nav>
        </>
    );
};
export default Myaccount;
