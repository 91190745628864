import styled from "styled-components";
import { usePagination, DOTS } from "./usePagination";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
        flex-direction: column;
        margin-top: 5px;
    }
`;
const TotalPageWrapper = styled.div`
    font-size: 14px;
    color: #282c3f;
    letter-spacing: 1px;
`;

const PageWrapper = styled.div`
    border: 0px solid #000;
    width: 100%;
    display: flex;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
`;

const PreviousCtn = styled.div`
    padding: 0 25px;
    padding: 10px 15px;
    color: #ff6e31;
    font-weight: 600;
    cursor: ${(props) => (props.isCurrentPage === 1 ? "initial" : "pointer")};
    pointer-events: ${(props) => (props.isCurrentPage === 1 ? "none" : "auto")};
    @media (max-width: 767px) {
        font-size: 13px;
    }
`;

const PageInner = styled.div`
    justify-content: center;
    display: flex;
    margin: 0 3px;
    @media (max-width: 767px) {
        margin: 0 1.5px;
    }
`;

const PageCtn = styled.div`
    display: flex;
    align-items: center;
`;

const PageNo = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    font-weight: 600;
    background: ${(props) =>
        props.isCurrentPage && props.rangeType != true ? "#ff6e31" : "#fff"};
    color: ${(props) =>
        props.isCurrentPage && props.rangeType != true ? "#fff" : "#282c3f"};
    cursor: ${(props) => (props.isCurrentPage ? "default" : "pointer")};
    pointer-events: ${(props) =>
        props.isCurrentPage == true ? "none" : "auto"};
    @media (max-width: 767px) {
        width: 25px;
        height: 25px;
    }
`;

const NextCtn = styled.div`
    padding: 0 25px;
    padding: 10px 15px;
    color: #ff6e31;
    font-weight: 600;
    cursor: ${(props) => (props.isLastPage == true ? "initial" : "pointer")};
    pointer-events: ${(props) => (props.isLastPage == true ? "none" : "auto")};
    @media (max-width: 767px) {
        font-size: 13px;
    }
`;

const ProductPagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPageNo = 1,
        pageSize = 20,
        totalPage,
    } = props;

    const currentPage = parseInt(currentPageNo);
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    if (currentPage === 0 || paginationRange?.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange?.length - 1];

    return (
        <PaginationContainer>
            <TotalPageWrapper>
                Page {currentPage} of {totalPage}
            </TotalPageWrapper>
            <PageWrapper>
                <PreviousCtn onClick={onPrevious} isCurrentPage={currentPage}>
                    Previous
                </PreviousCtn>
                <PageCtn>
                    {paginationRange?.map((pageNumber) => (
                        <PageInner key={pageNumber.toString()}>
                            <PageNo
                                isCurrentPage={
                                    pageNumber == currentPage ||
                                    typeof pageNumber == "string"
                                        ? true
                                        : false
                                }
                                rangeType={
                                    typeof pageNumber == "string" ? true : false
                                }
                                onClick={() => onPageChange(pageNumber)}
                            >
                                {pageNumber}
                            </PageNo>
                        </PageInner>
                    ))}
                </PageCtn>
                <NextCtn
                    onClick={onNext}
                    isLastPage={currentPage === totalPage ? true : false}
                >
                    Next
                </NextCtn>
            </PageWrapper>
        </PaginationContainer>
    );
};

export default ProductPagination;
