import Checkbox from "@mui/material/Checkbox";
import { useParams, useLocation } from "react-router-dom";
import {
	useQuery,
	useMutation,
	useQueryClient,
	QueryClient,
	QueryClientProvider,
} from "@tanstack/react-query";

import Announcement from "../../Components/Announcement/Announcement";
import Filtermenu from "../../Components/Announcement/Filtermenu";
import Topbar from "../../Components/Topbar/Topbar";
import Menubar from "../../Components/Menubar/Menubar";
import Footer from "../../Components/Layout/Footer";

import Filter from "../../Components/Filter/Filter";

import Products from "./Products";
import styled from "styled-components";

import { getCollectionFn, getProductFn } from "../../Api/Index";
import { ROUTES } from "../../Routes/Route";
import qs from "qs";

const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`
	width: 100%;
	margin: 0px auto;
	height: auto;
	@media (max-width: 1050px) {
		width: 98%;
	}
`;
const Wrapper = styled.div`
	display: flex;
	background: #f4f4f4;
	justify-content: space-between;
`;
const Filtercount = styled.div`
	width: 17%;
	margin: 6px 4px;
	background: white;
	padding: 18px 13px;
	border-radius: 4px;
	@media (max-width: 1050px) {
		width: 21%;
	}
`;

const Searcharea = styled.div`
	width: 78%;
	background: white;
	margin: 6px 4px;
	background: white;
	padding: 18px;
	border-radius: 4px;
`;
const Select = styled.select`
	padding: 8px;
	width: 100%;
	margin-top: 2px;
	font-size: 15px;
	font-weight: 300;
	border: none;
	border-radius: 6px;
	background-color: rgb(247, 247, 247);
`;
const Option = styled.option``;
const Label = styled.label``;
const Filtertext = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
`;
const Text = styled.div`
	color: rgb(104, 104, 104);
	font-size: 12px;
`;
const FilterCtn = styled.div`
	border-bottom: 1px solid rgb(221, 221, 221);
`;
const Space = styled.div``;
const TopFilter = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
`;
const Mid = styled.div`
	font-size: 14px;
	margin: 0px 10px;
	display: flex;
	align-items: end;
	color: rgb(138, 134, 134);
`;
const Headingtop = styled.div`
	font-size: 23px;
	font-weight: 400;
`;

const Search = () => {
	const location = useLocation();
	const params = useParams();
	const collection = params?.cid ? params?.cid : "";
	const queryParams = params["*"].split("/");

	const lastItem = queryParams.slice(-1);

	const collectionString = qs.stringify(
		{
			["slug"]: lastItem.join(","),
		},
		{ skipNull: true }
	);

	const prodString = qs.stringify(
		{
			["collection"]: lastItem.join(","),
		},
		{ skipNull: true }
	);

	// console.log("collectionString ", collectionString);

	const queryKey = "collection";
	const collection_url = `${ROUTES.getCollection}?${collectionString}`;

	const queryConfig = {
		refetchOnWindowFocus: false,
		// staleTime: 50000,
		// cacheTime: 10000,
		keepPreviousData: true,
	};

	const { data: filterList } = useQuery(
		[queryKey, collection_url],
		getCollectionFn,
		queryConfig
	);
	const collection_filter = filterList?.data?.collection_filter;

	const productQueryKey = "product";
	const product_url = location.search
		? `${ROUTES.getProduct}${location.search}&${prodString}`
		: `${ROUTES.getProduct}?&${prodString}`;

	// console.log("product_url ", product_url);
	// console.log("collectionString ", collectionString);

	const {
		data: productList,
		isLoading: isProductLoading,
		isFetching: isProductFetching,
		isError: isProductError,
		refetch,
	} = useQuery([productQueryKey, product_url], getProductFn, queryConfig);
	const productObj = productList?.data;

	console.log("productObj ", location.search);

	const array = [
		{
			id: 1,
			filter_name: "Size",
			options: [
				{
					id: 1,
					label: "SH",
					value: "sh",
				},
				{
					id: 2,
					label: "Size 3",
					value: "3",
				},
				{
					id: 3,
					label: "Size 4",
					value: "4",
				},
			],
			filter_key: "size",
		},
		{
			id: 2,
			filter_name: "Color",
			options: [
				{
					id: 1,
					label: "Red",
					value: "red",
				},
				{
					id: 2,
					label: "Blue",
					value: "blue",
				},
			],
			filter_key: "color",
		},
	];

	return <>Collection</>;
};

export default Search;
