import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const SkeletonCard = ({ limit }) => {
	return (
		<>
			{Array.from({ length: limit }).map((_, idx) => (
				<Stack spacing={1} key={idx}>
					<Skeleton variant="rectangular" height={180} />
					<Skeleton />
					<Skeleton animation="wave" />
					<Skeleton animation={false} />
				</Stack>
			))}
		</>
	);
};

export default SkeletonCard;
