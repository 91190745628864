import React from "react";
import Announcement from "../../Components/Announcement/Announcement";
import Slider from "../../Components/Slider/Slider";
import Menubar from "../../Components/Menubar/Menubar";
import Footer from "../../Components/Layout/Footer";
import Brands from "../Brands/Brands";
import Topbar from "../../Components/Topbar/Topbar";
import Navbar from "../../Components/Navbar/Navbar";
import AppBar from "@mui/material/AppBar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import styled from "styled-components";

import { UI } from "../../Constants/Index";

function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const Home = (props) => {
    return (
        <div>
            <Navbar start={false} />
            <Slider />
            <Brands />
            <Footer />
        </div>
    );
};

export default Home;
