// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAagywBtOMwLku2Bad_N30N9OVd9-etGSo",
    authDomain: "vishwa-sports.firebaseapp.com",
    projectId: "vishwa-sports",
    storageBucket: "vishwa-sports.appspot.com",
    messagingSenderId: "929429661932",
    appId: "1:929429661932:web:fe859b2c5283fdf0ea9dc9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
