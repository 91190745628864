import styled from "styled-components"


const Container = styled.div`
    width:100%;
`

const Image = styled.img`
    height:150px;
    width:100%;

`

const Slider = props => {

  return (
    <Container>
        <Image 
            src={props.url} 
        />
    </Container>
  )
}

export default Slider