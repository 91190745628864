import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Menubar from "../../Components/Menubar/Menubar";
import Footer from "../../Components/Layout/Footer";
import { FilterSharp } from "@mui/icons-material";
import Topbar from "../../Components/Topbar/Topbar";
import Navbar from "../../Components/Navbar/Navbar";
import MuiStyled from "styled-components";
import MuiButton from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import { UI } from "../../Constants/Index";
import {
    increaseQty,
    decreaseQty,
    removeFromCart,
    changeQty,
} from "../../Store/Action/Index";

const Container = styled.div`
    max-width: 1350px;
    margin: auto;
    margin-top: ${parseInt(UI.topBarHeight) + parseInt(UI.navBarHeight)}px;
    @media (max-width: 1050px) {
        width: 98%;
    }
`;
const Wrapper = styled.div`
    margin: auto;
    border: 0px solid rgb(0, 0, 0);
`;
const WrapperCtn = styled.div`
    border: 0px solid rgb(0, 0, 0);
`;
const TopHeading = styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 3px;
    color: rgb(119, 119, 119);
    line-height: 20px;
    margin-bottom: 5px;
    padding-top: 28px;
`;
const Anchorpara = styled.a`
    text-align: center;
    font-size: 14px;
    margin-top: 6px;
    margin-bottom: 50px;
    color: rgb(119, 119, 119);
    line-height: 13px;
    letter-spacing: 1px;
    display: block;
`;
const Section = styled.div`
    background: rgb(255, 255, 255);
    box-shadow: none;
    padding: 0px;
`;
const Filters = styled.div`
    display: flex;
    padding: 1px 11px;
    margin: 1px 0px;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    &:last-child {
        border-bottom: none;
    }
    @media (max-width: 642px) {
        display: block;
        padding: 12px 16px;
        margin-bottom: 22px;
        border-radius: 3px;
        box-shadow: 0px 0px 8px #dbdbdb;
    }
`;
const Textarea = styled.div`
    display: flex;
    flex: 5;
    font-family: initial;
    align-items: center;
    @media (max-width: 870px) {
        font-size: 13px;
    }
    @media (max-width: 642px) {
        display: block;
        font-size: 14px;
        width: 100%;
    }
`;
const Div = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const Description = styled.div`
    margin-left: 6px;
    padding: 0 10px;
    font-size: 13px;
    @media (max-width: 642px) {
        margin-left: 0px;
    }
`;
const Rupee = styled.div`
    margin-left: 6px;
    padding: 0 10px;
    font-size: 12px;
    @media (max-width: 642px) {
        width: 100%;
        margin-left: 0px;
        padding: 3px 10px;
    }
`;
const CartButton = styled.div`
    display: flex;
    flex: 3;
    justify-content: flex-end;
    @media (max-width: 642px) {
        width: 100%;
    }
`;
// const Button = styled.button`
//     color: #282828;
//     font-size: 16px;
//     align-items: center;
//     display: flex;
//     @media (max-width: 642px) {
//         margin-top: 8px;
//         padding: 3px 10px;
//     }
// `;

export const Button = MuiStyled(MuiButton)((props) => ({
    background: "black",
    color: "#fff",
}));

const Headingtext = styled.div`
    font-size: 13px;
    padding: 0 10px;
    margin-left: 6px;
    min-width: 100px;
    @media (max-width: 642px) {
        margin-left: 0px;
    }
`;

const EmptyCartInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 52px;
    background: #f7f7f7;
`;
const CartEmptyImage = styled.img`
    width: 200px;
`;
const MissingCart = styled.div`
    font-size: 20px;
    margin-top: 17px;
    text-align: center;
`;

const AskedForQoutation = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: sticky;
    bottom: 0;
    background: #fff;
    z-index: 9;
    padding: 8px 0;
    border-top: 1px solid #eee;
`;

const Input = styled.input`
    width: 50px;
`;

const TotalAmount = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Srno = styled.div`
    font-size: 12px;
    padding: 0;
    width: 24px;
    @media (max-width: 642px) {
        margin-left: 0px;
    }
`;

const Cart = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const CART = useSelector((state) => state.Cart);
    const AUTH = useSelector((state) => state.Auth);
    const isAuthorised = AUTH.isAuthorised;
    const total_amount = CART.totalAmount;

    const cartItems = useSelector((state) => {
        const transformedCartItems = [];
        for (const key in state.Cart.items) {
            transformedCartItems.push({
                product_id: key,
                productId: state.Cart.items[key].productId,
                product_name: state.Cart.items[key].product_name,
                brand_name: state.Cart.items[key].brandName,
                mrp: state.Cart.items[key].mrp,
                net_price: state.Cart.items[key].netPrice,
                order_qty: state.Cart.items[key].orderQuantity,
            });
        }
        return transformedCartItems.sort((a, b) =>
            a.product_id > b.product_id ? 1 : -1
        );
    });

    const removeProductHandler = (product_id) => {
        dispatch(removeFromCart(product_id));
    };

    const EmptyCart = (props) => {
        return (
            <EmptyCartInner>
                <CartEmptyImage src="https://kiaasaretail.s3.ap-south-1.amazonaws.com/emptycart.webp" />
                <MissingCart>Missing Cart items?</MissingCart>
                <Link to="/">View Brands</Link>
            </EmptyCartInner>
        );
    };

    const checkoutHandler = () => {
        isAuthorised == false
            ? navigate("/login?return_url=cart")
            : navigate("/checkout?return_url=cart");
    };

    const increaseQtyHandler = (product_id, newQty) => {
        dispatch(increaseQty(product_id, newQty));
    };

    const decreaseQtyHandler = (product_id, newQty) => {
        dispatch(decreaseQty(product_id, newQty));
    };

    const changeQtyHandler = (e, product_id) => {
        console.log(e.target);

        if (e.target.value) {
            const newQty = e.target.value;
            console.log("newQty ", newQty);
            dispatch(changeQty(product_id, newQty));
        } else {
            console.log(1);
            console.log(product_id);
        }
    };

    return (
        <>
            <Navbar />
            <Container>
                <Wrapper>
                    <WrapperCtn>
                        <TopHeading>Cart</TopHeading>
                        <Anchorpara href="/">Continue Shopping</Anchorpara>

                        <Section>
                            <Filters
                                style={{
                                    position: "sticky",
                                    top: 115,
                                    background: "rgb(240, 240, 240)",
                                    zIndex: 10,
                                    paddingTop: 7,
                                    paddingBottom: 7,
                                }}
                            >
                                <Textarea>
                                    <Srno>Sr. No.</Srno>
                                    <Headingtext>Brand</Headingtext>
                                    <Description>Product Name</Description>
                                </Textarea>
                                <CartButton>
                                    <Div>
                                        <Rupee>MRP</Rupee>
                                    </Div>
                                    <Div>
                                        <Rupee>Quantity</Rupee>
                                    </Div>
                                    <Div>
                                        <Rupee>Total</Rupee>
                                    </Div>
                                    <IconButton
                                        sx={{
                                            width: 30,
                                            height: 30,
                                            marginLeft: "5px",
                                            marginRight: "5px",
                                            color: "primary",
                                        }}
                                    >
                                        {/* <DeleteOutlineIcon /> */}
                                    </IconButton>
                                    {/* <Div> */}

                                    {/* </Div> */}
                                </CartButton>
                            </Filters>
                            {cartItems.length > 0 ? (
                                cartItems?.map((item, index) => {
                                    return (
                                        <Filters>
                                            <Textarea>
                                                <Srno>{index + 1}</Srno>
                                                <Headingtext>
                                                    {item.brand_name}
                                                </Headingtext>
                                                <Description>
                                                    {item.product_name}
                                                </Description>
                                            </Textarea>
                                            <CartButton>
                                                <Div>
                                                    <Rupee>
                                                        ₹{item.mrp} x{" "}
                                                    </Rupee>
                                                </Div>
                                                <Div>
                                                    <IconButton
                                                        disabled={
                                                            item.order_qty == 1
                                                                ? true
                                                                : false
                                                        }
                                                        sx={{
                                                            width: 20,
                                                            height: 20,
                                                            border: "1px solid #ddd",
                                                            marginLeft: "5px",
                                                            marginRight: "5px",
                                                        }}
                                                        onClick={(e) =>
                                                            decreaseQtyHandler(
                                                                item.productId,
                                                                1
                                                            )
                                                        }
                                                    >
                                                        <RemoveOutlinedIcon />
                                                    </IconButton>
                                                    <div key={item.order_qty}>
                                                        <TextField
                                                            onChange={(e) => {
                                                                let input =
                                                                    e.target
                                                                        .value;
                                                                if (
                                                                    !input ||
                                                                    (input[
                                                                        input.length -
                                                                            1
                                                                    ].match(
                                                                        "[0-9]"
                                                                    ) &&
                                                                        input[0].match(
                                                                            "[1-9]"
                                                                        ))
                                                                )
                                                                    changeQtyHandler(
                                                                        e,
                                                                        item.productId
                                                                    );
                                                            }}
                                                            key={
                                                                item.product_id
                                                            }
                                                            hiddenLabel
                                                            defaultValue={
                                                                item.order_qty
                                                            }
                                                            variant="standard"
                                                            size="small"
                                                            sx={{
                                                                width: 50,
                                                                height: 20,
                                                                border: "1px solid #ddd",
                                                                textAlign:
                                                                    "center",
                                                                justifyContent:
                                                                    "center",
                                                                borderRadius: 5,
                                                                fontSize: 11,
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                inputProps: {
                                                                    style: {
                                                                        textAlign:
                                                                            "center",
                                                                        padding: 0,
                                                                        justifyContent:
                                                                            "center",
                                                                    },
                                                                    allowNegative: false,
                                                                    pattern:
                                                                        "([^0-9]*)",
                                                                },
                                                            }}
                                                            error={
                                                                "Error Message"
                                                            }
                                                        />
                                                    </div>
                                                    <IconButton
                                                        sx={{
                                                            width: 20,
                                                            height: 20,
                                                            border: "1px solid #ddd",
                                                            marginLeft: "5px",
                                                            marginRight: "5px",
                                                        }}
                                                        onClick={(e) =>
                                                            increaseQtyHandler(
                                                                item.productId,
                                                                1
                                                            )
                                                        }
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Div>
                                                <Div>
                                                    <Rupee>
                                                        = ₹{item.net_price}
                                                    </Rupee>
                                                </Div>
                                                {/* <Div> */}

                                                <IconButton
                                                    sx={{
                                                        width: 20,
                                                        height: 20,
                                                        marginLeft: "5px",
                                                        marginRight: "5px",
                                                        color: "primary",
                                                    }}
                                                    onClick={() =>
                                                        removeProductHandler(
                                                            item.product_id
                                                        )
                                                    }
                                                >
                                                    <DeleteOutlineIcon />
                                                </IconButton>

                                                {/* </Div> */}
                                            </CartButton>
                                        </Filters>
                                    );
                                })
                            ) : (
                                <EmptyCart />
                            )}
                        </Section>

                        {/* <TotalAmount>Total Amount: ₹{total_amount}</TotalAmount> */}

                        {cartItems.length > 0 ? (
                            <AskedForQoutation>
                                <TotalAmount>
                                    Total Items: {cartItems.length}
                                </TotalAmount>
                                <Button
                                    variant="contained"
                                    sx={{
                                        height: 38,
                                        fontSize: 20,
                                        fontWeight: 700,
                                        fontFamily: "Heebo",
                                        color: "#fff",
                                    }}
                                    onClick={checkoutHandler}
                                >
                                    Proceed To Checkout
                                </Button>
                                <TotalAmount>
                                    Total Amount: ₹{total_amount}
                                </TotalAmount>
                            </AskedForQoutation>
                        ) : null}
                    </WrapperCtn>
                </Wrapper>
            </Container>

            <Footer />
        </>
    );
};

export default Cart;
