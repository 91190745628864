export const ADD_ORDER_ITEM_TO_CART_TO_UPDATE =
    "ADD_ORDER_ITEM_TO_CART_TO_UPDATE";
export const ON_CHANGE_QTY = "ON_CHANGE_QTY";

export const addOrderItemToCartToUpdate = (item) => {
    return {
        type: ADD_ORDER_ITEM_TO_CART_TO_UPDATE,
        payload: item,
    };
};

export const onChangeQty = (item) => {
    return { type: ON_CHANGE_QTY, payload: item };
};
