import styled from "styled-components";
import Topbar from "../../Components/Topbar/Topbar";
import Menubar from "../../Components/Menubar/Menubar";
import Footer from "../../Components/Layout/Footer";
import { useState } from "react";

const Container = styled.div`
    margin: 0px auto;
    width: 1050px;
    @media (max-width: 1050px) {
        width: 95%;
    }
`;
const Wrapper = styled.div`
    display: flex;
`;
const Accordion = styled.div`
    margin: 0px auto;
    padding: 38px 18px;
`;
const Item = styled.div`
    margin-bottom: 18px;
    border-radius: 4px;
    padding: 10px 22px;
    cursor: pointer;
    background-color: rgb(247, 247, 247);
    box-shadow: 0px 0px 8px #d9d9d9;
`;
const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Span = styled.div`
    font-size: 18px;
`;
const Heading = styled.h1`
    font-size: 14px;
    font-weight: bold;
    @media (max-width: 548px) {
        font-size: 13px;
    }
`;
const Description = styled.div`
    color: #494444;
    @media (max-width: 548px) {
        font-size: 12px;
    }
`;
const TopHeading = styled.h2`
    padding-bottom: 24px;
    font-size: 24px;
    text-align: center;
`;
const Detailarea = styled.div`
    margin-top: 12px;
`;
const Div = styled.div`
    margin-top: 1px;
`;
const Faq = () => {
    const data = [
        {
            id: 1,
            title: "How do I place an order on Vishwa Sports?",
        },
    ];

    const [selected, setSelected] = useState(null);

    const toggle = (i) => {
        if (selected == i) {
            return setSelected(null);
        }
        setSelected(i);
    };

    return (
        <>
            <Topbar />
            <Menubar />
            <Container>
                <Wrapper>
                    <Accordion>
                        <TopHeading>FAQ's / Help</TopHeading>
                        {data.map((item, i) => (
                            <Item>
                                <Title onClick={() => toggle(i)}>
                                    <Heading>{item.title}</Heading>
                                    <Span>{selected == i ? "-" : "+"}</Span>
                                </Title>
                                <Description
                                    className={
                                        selected == i
                                            ? "contentshow"
                                            : "content"
                                    }
                                >
                                    {item.description}
                                    <br />
                                    <Detailarea>
                                        <Div>
                                            {item.detailtop} <b>{item.add}</b>
                                        </Div>
                                        <Div>
                                            {item.detailmid}{" "}
                                            <b>{item.checkout}</b>
                                        </Div>
                                        <Div>{item.position}</Div>
                                        <Div>{item.first}</Div>
                                        <Div>{item.second}</Div>
                                        <Div> {item.third}</Div>
                                        <Div>
                                            {item.detaildown}{" "}
                                            <b>{item.prepaid}</b> {item.or}{" "}
                                            <b>{item.cash}</b>
                                        </Div>
                                        <Div>{item.down}</Div>
                                    </Detailarea>
                                    <Detailarea>
                                        <Div>{item.one}</Div>
                                        <Div>{item.two}</Div>
                                        <Div>{item.three}</Div>
                                        <Div>{item.four}</Div>
                                        <Div>{item.five}</Div>
                                    </Detailarea>
                                </Description>
                            </Item>
                        ))}
                    </Accordion>
                </Wrapper>
            </Container>

            <Footer />
        </>
    );
};

export default Faq;
