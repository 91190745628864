import styled from "styled-components";

import FooterLink from "./FooterLink";
import Copyright from "./Copyright";

import { footer } from "./Data";

const FooterContainer = styled.footer`
    width: 100%;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`;
const Wrapper = styled.div`
    max-width: 1050px;
    margin: 50px auto 50px;
    display: flex;
    @media (max-width: 767px) {
        display: block;
        padding: 1rem;
    }
`;
const Border = styled.div`
    width: 100%;
    border-top: 1px solid #2b2b2b;
`;

const Image = styled.img`
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 25px;
    right: 45px;
`;

const Footer = () => {
    // console.log(footer.widgets)
    const url = "/assets/whatsapp.png";
    const url1 = "/assets/2.webp";

    const WhatsApp = () => {
        return (
            <a href="https://wa.me/919719206654">
                <Image src={url1} alt={"Vishwa Sports Co."} />
            </a>
        );
    };

    return (
        <FooterContainer>
            <Wrapper>
                {footer.widgets?.map((widget) => (
                    <FooterLink
                        key={`footer-widget--key${widget.id}`}
                        data={widget}
                    />
                ))}
            </Wrapper>
            <Border />
            <Copyright />
            <WhatsApp />
        </FooterContainer>
    );
};

export default Footer;
