import * as React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Skeleton from "@mui/material/Skeleton";

import styled from "styled-components";

const Container = styled.div`
    padding: 10px;
    display: flex;
    justify-content: space-between;
`;

const Left = styled.div`
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    width: 100%;
`;

const Right = styled.div``;

const Filters = styled.div`
    display: flex;
    background: #fff;
    padding: 1px 11px;
    margin-bottom: 2px;
    justify-content: space-between;
    border: 1px solid #f1f1f1;
    min-height: 20px;
    @media (max-width: 642px) {
        display: block;
        padding: 12px 16px;
        margin-bottom: 22px;
        border-radius: 3px;
        box-shadow: 0px 0px 8px #dbdbdb;
    }
`;
const Textarea = styled.div`
    display: flex;
    gap: 5px;
    width: 100%;
    font-family: initial;
    align-items: center;
    @media (max-width: 870px) {
        font-size: 13px;
    }
    @media (max-width: 642px) {
        display: block;
        font-size: 14px;
        width: 100%;
    }
`;
const Div = styled.div`
    display: flex;
`;

const Description = styled.div`
    margin-left: 6px;
    padding: 3px 10px;
    background: rgb(247, 247, 247);
    @media (max-width: 642px) {
        margin-left: 0px;
    }
`;
const Rupee = styled.div`
    margin-left: 6px;
    background: rgb(247, 247, 247);
    padding: 3px 10px;
    @media (max-width: 642px) {
        width: 100%;
        margin-left: 0px;
        padding: 3px 10px;
    }
`;
const CartButton = styled.div`
    width: 19%;
    @media (max-width: 642px) {
        width: 100%;
    }
`;
const Button = styled.button`
    font-size: 14px;
    display: block;
    text-decoration: none;
    text-align: center;
    padding: 8px 12px;
    background: black;
    color: white;
    border-radius: 5px;
    @media (max-width: 900px) {
        font-size: 11px;
    }
    @media (max-width: 642px) {
        margin-top: 10px;
        font-size: 14px;
    }
    &:hover {
        background: #3e3b3b;
    }
`;
const Headingtext = styled.h2`
    font-size: 14px;
    padding: 3px 10px;
    margin-left: 6px;
    color: gray;
    background: rgb(247, 247, 247);
    font-weight: bold;
    @media (max-width: 642px) {
        margin-left: 0px;
    }
`;

function SkeletonCard(props) {
    const { loading = false } = props;
    return (
        <Filters>
            <Textarea>
                <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={70}
                    height={20}
                    sx={{ bgcolor: "#eae9e9" }}
                />
                <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={"40%"}
                    height={20}
                    sx={{ bgcolor: "#eae9e9" }}
                />
                <Skeleton
                    animation="wave"
                    variant="rounded"
                    width={70}
                    height={20}
                    sx={{ bgcolor: "#eae9e9" }}
                />
            </Textarea>
            <Skeleton
                animation="wave"
                variant="rounded"
                width={150}
                height={20}
                sx={{ bgcolor: "#eae9e9" }}
            />
        </Filters>
    );
}

SkeletonCard.propTypes = {
    loading: PropTypes.bool,
};

export default function Facebook({ limit }) {
    return (
        <>
            {Array.from({ length: limit }).map((_, idx) => (
                <SkeletonCard loading key={idx} />
            ))}
        </>
    );
}
