import styled from "styled-components"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Topbar from '../../Components/Topbar/Topbar'
import Menubar from '../../Components/Menubar/Menubar'
import Footer from '../../Components/Layout/Footer'
import AppSection from '../../Components/AppSection/AppSection'
import { Email, Filter } from "@mui/icons-material";
import { Identity } from "@mui/base";
import Zoom from 'react-medium-image-zoom'
import { Link } from 'react-router-dom';
import Iframe from 'react-iframe'

const Container = styled.div`
  max-width:1050px;
  margin:0px auto;
  @media(max-width:1050px)
  {
    width:95%;
  }
`
const Wrapper = styled.div`
display:flex;
padding: 52px 0px;
@media(max-width:767px)
{
    display:block;
}
` 
const Image = styled.img`
height: 160px;
width: 100%;
`
const RightWrapper = styled.div`
margin-left: 12px;
width: 68%;
padding:14px 16px;
@media(max-width:767px)
{
    width: 95%;
    margin-left:0px;
    margin-top:22px;
}
@media(max-width:520px)
{
    width: 92%;
}
@media(max-width:360px)
{
    width: 90%;
}
`
const LeftWrapper = styled.div`
width: 36%;
padding:14px 30px;
border: 1px solid #e6e2e2;
@media(max-width:767px)
{
    width: 95%;
    padding: 14px 16px;
}
@media(max-width:520px)
{
    width: 92%;
}
`
const Innerwrapper =styled.div`
display:flex;
font-size: 13px;
padding-bottom: 28px;
`
const Heading = styled.h1`
font-size: 19px;
font-weight: bold;
`
const Section = styled.div`
margin-left: 7px;
`

const Icon = styled.div`
padding: 8px 10px;
border: 1px solid #d7d5d5;
height: 14px;
border-radius: 4px;
`

const TopHeading = styled.h1`
font-size: 18px;
padding-bottom:28px;
font-weight: bold;
`
const InnerHeading = styled.h2`
font-size: 14px;
margin-bottom: 5px;
color: #565353;
font-weight: normal;

`
const Form = styled.form`

`
const FormCtn = styled.div`
display: flex;
margin-top: 12px;
justify-content: space-between;
@media(max-width:767px)
{
    display:block;

}
`
const Input = styled.input`
width:100%;
height:32px;
padding:3px 5px;
border-radius: 4px;
border: 1px solid #dedede;
&::placeholder {
    color: #c8c8c8;
    opacity: 1;
  }
&:focus {
    outline: none;
    box-shadow: 0px 0px 2px red;
}
&:: -webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

`
const Textarea = styled.div`
margin-top:12px;
`
const Formwrapper = styled.div`
width: 48%;
@media(max-width:767px)
{
    width: 100%;
    margin-top: 12px;
    
}
` 
const StyledTextarea = styled.textarea`
width:100%;
padding:3px 5px;
border-radius: 4px;
height: 118px;
border: 1px solid #dedede;
&::placeholder {
    color: #c8c8c8;
    opacity: 1;
  }
&:focus {
    outline: none;
    box-shadow: 0px 0px 2px red;
}
&:: -webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
`
const Button = styled.button`
display:block;
color: white;
background: black;
margin-left: 4px;
padding: 8px 22px;
text-align: center;
border-radius: 4px;

`
const company = "Vishwa Sports Co"
const map = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.218543894573!2d77.43483311409676!3d28.653174289899287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf109c01ab813%3A0xea6c3d3a21d992b4!2sKiaasa%20Ghaziabad!5e0!3m2!1sen!2sin!4v1673945702858!5m2!1sen!2sin"

const ContactusPage = () => {
  return (
    <>
        <Topbar/>
          <Menubar/>
        
    <Container className="px-4">
        <Wrapper>


            <LeftWrapper>
                    <TopHeading>Find us here</TopHeading>

                    <Innerwrapper>
                            <Icon>
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M256 32C167.67 32 96 96.51 96 176c0 128 160 304 160 304s160-176 160-304c0-79.49-71.67-144-160-144zm0 224a64 64 0 1164-64 64.07 64.07 0 01-64 64z"></path>
                                </svg>
                            </Icon>
                            <Section>
                                <InnerHeading>Address</InnerHeading>
                                <Link to ="/">{company}<br />
                                Suraj Kund Road Meerut, 0121-4056790, 4331774, 9719206654
                                </Link>
                            </Section>
                    </Innerwrapper>

                    <Innerwrapper>
                        <Icon>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M424 80H88a56.06 56.06 0 00-56 56v240a56.06 56.06 0 0056 56h336a56.06 56.06 0 0056-56V136a56.06 56.06 0 00-56-56zm-14.18 92.63l-144 112a16 16 0 01-19.64 0l-144-112a16 16 0 1119.64-25.26L256 251.73l134.18-104.36a16 16 0 0119.64 25.26z"></path>
                            </svg>
                        </Icon>
                        <Section>
                            <InnerHeading>Email</InnerHeading>
                            <Link to="/">care@vishwasports.in</Link>
                        </Section>
                    </Innerwrapper>

                    <Innerwrapper>
                        <Icon>
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M478.94 370.14c-5.22-5.56-23.65-22-57.53-43.75-34.13-21.94-59.3-35.62-66.52-38.81a3.83 3.83 0 00-3.92.49c-11.63 9.07-31.21 25.73-32.26 26.63-6.78 5.81-6.78 5.81-12.33 4-9.76-3.2-40.08-19.3-66.5-45.78s-43.35-57.55-46.55-67.3c-1.83-5.56-1.83-5.56 4-12.34.9-1.05 17.57-20.63 26.64-32.25a3.83 3.83 0 00.49-3.92c-3.19-7.23-16.87-32.39-38.81-66.52-21.78-33.87-38.2-52.3-43.76-57.52a3.9 3.9 0 00-3.89-.87 322.35 322.35 0 00-56 25.45A338 338 0 0033.35 92a3.83 3.83 0 00-1.26 3.74c2.09 9.74 12.08 50.4 43.08 106.72 31.63 57.48 53.55 86.93 100 133.22S252 405.21 309.54 436.84c56.32 31 97 41 106.72 43.07a3.86 3.86 0 003.75-1.26A337.73 337.73 0 00454.35 430a322.7 322.7 0 0025.45-56 3.9 3.9 0 00-.86-3.86z"></path>
                            </svg>
                        </Icon>
                        <Section>
                            <InnerHeading>Phone</InnerHeading>
                            <Link to="/">Whatsapp: +919719206654</Link>
                        </Section>
                    </Innerwrapper>

                    <Zoom>
                        <Iframe url={map}  width="100%" height="220px" style="border:0;" allowfullscreen=""  loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
                    </Zoom>    
            </LeftWrapper>

            <RightWrapper>
                <Heading>Get in touch</Heading>
                <Form>
                    <FormCtn>
                        <Formwrapper>
                            <InnerHeading> Your Name (required) </InnerHeading>
                            <Input id="name" name="name" type="text" placeholder="Enter Your Name"  autocomplete="off"spellcheck="false"aria-invalid="false"/>
                        </Formwrapper>
                        <Formwrapper>
                            <InnerHeading> Your Email (required) </InnerHeading>
                            <Input id="email" name="email" type="email" placeholder="Enter Your Email"  autocomplete="off"spellcheck="false"aria-invalid="false"/>
                        </Formwrapper> 
                    </FormCtn>
                    <Textarea>
                            <InnerHeading> Subject </InnerHeading>
                            <Input id="subject" name="subject" type="text" placeholder="Enter Your Subject" autocomplete="off"spellcheck="false"aria-invalid="false"/>
                    </Textarea>

                    <Textarea>
                            <InnerHeading> Message </InnerHeading>
                            <StyledTextarea type="text"autocomplete="off" spellcheck="false" rows="4" placeholder="Write your message here"/>
                    </Textarea>
                    <Textarea>
                            <Button type="submit" as="a" href="/">Send Message</Button> 
                    </Textarea>
                </Form>


            </RightWrapper>


        </Wrapper>
    </Container>
          <Footer />
    </>
  )
}

export default ContactusPage;

