import Checkbox from '@mui/material/Checkbox';
import Announcement from '../../Components/Announcement/Announcement'
import Footer from '../../Components/Layout/Footer'
import AppSection from '../../Components/AppSection/AppSection'
import Product from '../../Components/Product/Product'

import styled from "styled-components"

const Container = styled.div`
  display:flex;
  width:100%;
  margin:auto;
`

const FilterContainer = styled.div`
  display:flex;
  width:22%;
  padding:10px;
  justify-content:space-between;
  
`
const Filter = styled.div`
  margin:20px;
  width:100%;
  border:1px solid #000;
  
`

const Filters = styled.div`
  margin:20px 0;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
`


const FilterSection = styled.div`
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    border: 1px solid #e1e1e1;
    padding: 10px;
`

const Filtered = styled.div`
  background-color: #f7f7f7;
  padding: 4px 25px;
  border-radius: 6px;
  font-weight: 400;
  border: .5px solid #d5d5d5;
  margin-right: 5px;
  font-size: .75rem;
  letter-spacing: 1.2px;
`

const CheckedFiltered = styled.div`
  display:flex;
`

const FilterText = styled.div`
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 20px;
`

const FilterCtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 17px;
`

// const Select = styled.select`
//   background-color: #f7f7f7;
//   padding: 4px 25px;
//   border-radius: 6px;
//   font-weight: 600;
//   border: .5px solid #d5d5d5;
//   font-size:18px;
// `
// const Option = styled.option``

// const Label = styled.label`
//   margin-bottom:10px;
// `

const ProductList = () => {

  return (
    <>
        <Announcement />
        <Container>
            <FilterContainer>
                <Filter>
                    <FilterSection>
                        <FilterText>
                            Filter
                        </FilterText>
                        <CheckedFiltered>
                          <Filtered>Cricket</Filtered>
                          <Filtered>Kashmir Willow</Filtered>
                        </CheckedFiltered>

                    </FilterSection>


                    <Filters>
                      
                      <FilterCtn>
                        <h3>Category</h3>
                        <div className="filterhd">
                          <label class="filterlabel">
                            <Checkbox />
                              <span class="ms-4 -mt-0.5">Woman</span>
                          </label>
                          <label class="filterlabel">
                            <Checkbox />
                              <span class="ms-4 -mt-0.5">Woman</span>
                          </label>
                          <label class="filterlabel">
                            <Checkbox />
                              <span class="ms-4 -mt-0.5">Woman</span>
                          </label>
                        </div>

                      </FilterCtn>

                      {/* <FilterCtn>
                        <Label>Color</Label>
                        <Select>
                            <Option disabled selected>Color</Option>
                            <Option>White</Option>
                            <Option>Black</Option>
                            <Option>Red</Option>
                        </Select>
                      </FilterCtn> */}

                      {/* <FilterCtn>
                        <Label>Size</Label>
                        <Select>
                            <Option disabled selected>Size</Option>
                            <Option>M</Option>
                            <Option>L</Option>
                            <Option>XL</Option>
                        </Select>
                      </FilterCtn> */}

                    </Filters>
                    

                </Filter>
                {/* <Filter>
                    <FilterText>Sort Products</FilterText>
                </Filter> */}
            </FilterContainer>
            <Product />
        

        </Container>
        <AppSection />
        <Footer />

    </>
  )
}

export default ProductList