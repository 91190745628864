import * as React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";

import Menubar from "../Menubar/Menubar";
import Topbar from "../Topbar/Topbar";

function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 1 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

const Navbar = (props) => {
    return (
        <>
            <ElevationScroll {...props}>
                <AppBar style={{ background: "#fff" }}>
                    <Topbar />
                    <Menubar />
                </AppBar>
            </ElevationScroll>
        </>
    );
};

export default Navbar;
