
import { productList } from "../../Data/ProductList"


const Product = () => {

    return (
        <div className="productListCtn">

            {
                productList.map((item, index) => (
                    <div
                        className="lop"
                    >
                        <div
                            className="bat"
                        >
                                            
                            <div
                                className="prodg"
                            >
                                <div className="welld">
                                    <h2
                                        className=""
                                    >
                                        {item?.brand}
                                    </h2>
                                    <h2
                                        className=""
                                    >
                                        {item?.product_name}
                                    </h2>
                                    <h2
                                        className=""
                                    >
                                        MRP: ₹{item.net_price}
                                    </h2>
                                </div>
                                <button
                                >
                                    Add To Quote
                                </button>
                            </div>
                        </div>
                    </div>
                ))
            }

        </div>
    )
}

export default Product