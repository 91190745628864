import { SET_LOGGED_USER } from "../../Action/Auth/Auth";

const initialState = {
    isAuthorised: false,
    user: {},
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOGGED_USER:
            return {
                ...state,
                isAuthorised: true,
            };

        default:
            return state;
    }
}
