import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Menubar from "../../Components/Menubar/Menubar";
import Footer from "../../Components/Layout/Footer";
import { FilterSharp } from "@mui/icons-material";
import Topbar from "../../Components/Topbar/Topbar";
import Navbar from "../../Components/Navbar/Navbar";
import MuiStyled from "styled-components";
import MuiButton from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { TextField, FormControl, Card, CardContent, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { SnackbarProvider, useSnackbar } from "notistack";

import { UI } from "../../Constants/Index";
import {
    removeFromCart,
    increaseQty,
    decreaseQty,
} from "../../Store/Action/Index";

import RealmInstance from "../../Config/Config";
import useAxiosPrivate from "../../Api/useAxiosPrivate";
import { addOrderFn } from "../../Api/Index";

import Order_m from "../../Models/Order_m";

const Container = styled.div`
    width: 1250px;
    margin: auto;
    margin-top: ${parseInt(UI.topBarHeight) + parseInt(UI.navBarHeight)}px;
    @media (max-width: 1050px) {
        width: 98%;
    }
`;
const Wrapper = styled.div`
    margin: auto;
    border: 0px solid rgb(0, 0, 0);
`;
const WrapperCtn = styled.div`
    border: 0px solid rgb(0, 0, 0);
    margin-bottom: 50px;
`;
const TopHeading = styled.div`
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 3px;
    color: rgb(119, 119, 119);
    line-height: 20px;
    margin-bottom: 5px;
    padding-top: 28px;
`;

export const Button = MuiStyled(MuiButton)((props) => ({
    background: "black",
    color: "#fff",
}));

const EmptyCartInner = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 52px;
    background: #f7f7f7;
`;
const CartEmptyImage = styled.img`
    width: 200px;
`;
const MissingCart = styled.div`
    font-size: 20px;
    margin-top: 17px;
    text-align: center;
`;

const schema = yup
    .object({
        firstName: yup.string(),
        lastName: yup.string(),
        email: yup.string().email(),
        whatsappNo: yup.string().required(),
        message: yup.string(),
    })
    .required();

const Cart = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const axiosPrivate = useAxiosPrivate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const CART = useSelector((state) => state.Cart);
    const AUTH = useSelector((state) => state.Auth);
    const isAuthorised = AUTH.isAuthorised;
    const total_amount = CART.totalAmount;

    const cartItems = useSelector((state) => {
        const transformedCartItems = [];
        for (const key in state.Cart.items) {
            transformedCartItems.push({
                product_id: key,
                productId: state.Cart.items[key].productId,
                product_name: state.Cart.items[key].product_name,
                brand_name: state.Cart.items[key].brandName,
                mrp: state.Cart.items[key].mrp,
                dealer_price: state.Cart.items[key].dealerPrice,
                net_price: state.Cart.items[key].netPrice,
                order_qty: state.Cart.items[key].orderQuantity,
                gst: state.Cart.items[key].gst,
            });
        }
        return transformedCartItems.sort((a, b) =>
            a.product_id > b.product_id ? 1 : -1
        );
    });

    const addOrderMutation = useMutation(
        (data) => addOrderFn(data, "addCustomerOrder", axiosPrivate),
        {
            onSuccess: (response) => {
                // Invalidates cache and refresh

                console.log("success mutation", response);

                if (response?.status == "success") {
                    // setLoader(false);
                    enqueueSnackbar("Case added successfully!", {
                        variant: "success",
                    });
                    // setSubCaseType("state");
                    // reset();
                } else if (response?.response?.status === 400) {
                    // setLoader(false);
                    enqueueSnackbar("Something went wrong", {
                        variant: "error",
                    });
                } else {
                    // setLoader(false);
                    enqueueSnackbar("Something went wrong", {
                        variant: "error",
                    });
                }

                queryClient.invalidateQueries("case");
                // refetch()
            },
            onError: (error) => {
                // Invalidates cache and refresh

                console.log("error mutation ", error);

                queryClient.invalidateQueries("case");
                enqueueSnackbar("Something went wrong", { variant: "error" });
                // refetch()
            },
        }
    );

    const onSubmit = (data) => {
        const customer_id = RealmInstance?.currentUser.id;
        const created_by = RealmInstance?.currentUser.id;
        const first_name = data.firstName;
        const last_name = data.lastName;
        const customer_email = data.email;
        const customer_whatsapp_no = data.whatsappNo;
        const order_amount = total_amount;
        const status = "waiting for admin";
        const order_date = "2023-06-16";
        const last_updated_on = "2023-06-17";
        const message = data.message;

        const newOrderObj = new Order_m(
            customer_id,
            created_by,
            first_name,
            last_name,
            customer_email,
            customer_whatsapp_no,
            order_amount,
            status,
            message,
            order_date,
            last_updated_on
        );

        const newOrderItem = newOrderObj.getOrderItem(cartItems);

        newOrderObj.orderItems = newOrderItem;

        const addOrderBody = JSON.stringify(newOrderObj);
        // console.log("cartItems checkout 1", addOrderBody);

        addOrderMutation.mutate(addOrderBody);
    };

    const removeProductHandler = (product_id) => {
        dispatch(removeFromCart(product_id));
    };

    const EmptyCart = (props) => {
        return (
            <EmptyCartInner>
                <CartEmptyImage src="https://kiaasaretail.s3.ap-south-1.amazonaws.com/emptycart.webp" />
                <MissingCart>Missing Cart items?</MissingCart>
                <Link to="/">View Brands</Link>
            </EmptyCartInner>
        );
    };

    const checkoutHandler = () => {
        isAuthorised == false
            ? navigate("/login?return_url=cart")
            : navigate("/checkout?return_url=cart");
    };

    const increaseQtyHandler = (product_id, newQty) => {
        dispatch(increaseQty(product_id, newQty));
    };

    const decreaseQtyHandler = (product_id, newQty) => {
        dispatch(decreaseQty(product_id, newQty));
    };

    return (
        <>
            <Navbar />
            <Container>
                <Wrapper>
                    <WrapperCtn>
                        <TopHeading>Checkout</TopHeading>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container mt={5} spacing={2}>
                                <Grid item xs={12} sm={12} md={7}>
                                    <Typography
                                        sx={{ fontFamily: "Heebo, sans-serif" }}
                                    >
                                        Customer Detail
                                    </Typography>
                                    <Grid item spacing={1}>
                                        <Grid item mt={0.5} mb={2}>
                                            <Typography
                                                sx={{
                                                    fontFamily:
                                                        "Heebo, sans-serif",
                                                    fontSize: 10,
                                                }}
                                            >
                                                Please fill up your detail
                                                carefully.
                                            </Typography>
                                        </Grid>
                                        <Grid container spacing={1} mt={1}>
                                            <Grid xs={12} sm={6} item>
                                                <TextField
                                                    {...register("firstName")}
                                                    label="Firstname"
                                                    placeholder="Enter first name"
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    helperText={
                                                        errors.firstName
                                                            ? "Invalid firstname"
                                                            : ""
                                                    }
                                                    error={
                                                        errors.firstName
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </Grid>
                                            <Grid xs={12} sm={6} item>
                                                <TextField
                                                    {...register("lastName")}
                                                    label="Lastname"
                                                    placeholder="Enter last name"
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    helperText={
                                                        errors.lastName
                                                            ? "Invalid lastname"
                                                            : ""
                                                    }
                                                    error={
                                                        errors.lastName
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </Grid>
                                            <Grid xs={12} sm={6} item>
                                                <TextField
                                                    {...register("email")}
                                                    label="Email"
                                                    placeholder="Enter your email"
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    helperText={
                                                        errors.email
                                                            ? "Invalid email"
                                                            : ""
                                                    }
                                                    error={
                                                        errors.email
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </Grid>
                                            <Grid xs={12} sm={6} item>
                                                <TextField
                                                    {...register("whatsappNo")}
                                                    label="WhatsApp Number*"
                                                    placeholder="Enter your WhatsApp number"
                                                    variant="outlined"
                                                    fullWidth
                                                    size="small"
                                                    helperText={
                                                        errors.whatsappNo
                                                            ? "Invalid whatsapp no"
                                                            : ""
                                                    }
                                                    error={
                                                        errors.whatsappNo
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </Grid>
                                            <Grid xs={12} item>
                                                <TextField
                                                    {...register("message")}
                                                    label="Message"
                                                    placeholder="Enter your message"
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    helperText={
                                                        errors.message
                                                            ? "Invalid message"
                                                            : ""
                                                    }
                                                    error={
                                                        errors.message
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={5}>
                                    <Typography
                                        sx={{ fontFamily: "Heebo, sans-serif" }}
                                    >
                                        Order Summary
                                    </Typography>
                                    <Grid item spacing={1}>
                                        <Grid item mt={0.5} mb={2}>
                                            <Typography
                                                sx={{
                                                    fontFamily:
                                                        "Heebo, sans-serif",
                                                    fontSize: 10,
                                                }}
                                            >
                                                This is your order summary .
                                            </Typography>
                                        </Grid>
                                        <Grid container spacing={1} mt={1}>
                                            <Grid xs={12} sm={6} md={12} item>
                                                <Card>
                                                    <CardContent fullWidth>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    "Heebo, sans-serif",
                                                                fontSize: 13,
                                                            }}
                                                        >
                                                            Total Items in Cart:
                                                            45
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontFamily:
                                                                    "Heebo, sans-serif",
                                                                fontSize: 13,
                                                            }}
                                                        >
                                                            Total Amount: ₹
                                                            {total_amount}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container mt={2} justify="center">
                                <Grid xs={12} item>
                                    <Button type="submit" variant="contained">
                                        Ask For Qoutation
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </WrapperCtn>
                </Wrapper>
            </Container>

            <Footer />
        </>
    );
};

export default Cart;
