import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";

const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`
    flex: 0.4;
    @media (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const Anchor = styled.a`
    width: 124px;
    height: 18px;
    display: block;
`;

const Image = styled.img`
    width: 100%;
`;

const logo = "https://vishwasports.s3.ap-south-1.amazonaws.com/gravity.png";

const Left = () => {
    return (
        <Container>
            <Anchor href="/">
                <Image src={logo} alt="Vishwa Sports" />
            </Anchor>
        </Container>
    );
};

const Menu = () => {
    return (
        <Container>
            <MenuIcon />
        </Container>
    );
};

export default width > 996 ? Left : Menu;
