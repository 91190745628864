import { useState, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    useParams,
    useSearchParams,
    useLocation,
    useNavigate,
    useMatch,
} from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import qs from "qs";
import * as Realm from "realm-web";
import jwt_decode from "jwt-decode";
import { Grid, Button, Box } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import { SnackbarProvider, useSnackbar } from "notistack";

import {
    availableQtyRef,
    rateTypeRef,
    discountRef,
    priceByRateTypeRef,
} from "./order_ref_formula";

import {
    calculateNetRate,
    calculateGrossMargin,
    caculatePriceWithTax,
    calculateNetMargin,
    calculateFinalPrice,
    setCalculatedField,
    caculatePriceWithoutTax,
} from "./order_calculation_method";

import styled from "styled-components";
import Navbar from "../../../Components/Navbar/Navbar";
import Footer from "../../../Components/Layout/Footer";
import Nav from "../Nav";
import AccountLayout from "../../../Components/MyAccount/AccountLayout";

import { ROUTES } from "../../../Routes/Route";
import { getOrderFn, updateOrderFn } from "../../../Api/Index";
import useAxiosPrivate from "../../../Api/useAxiosPrivate";
import OrderPagination from "./PaginationContainer";

import { UI } from "../../../Constants/Index";
import Order_m from "../../../Models/Order_m";

import { onChangeQty } from "../../../Store/Action/Index";

const Container = styled.div`
    margin: 0px auto;
    margin-top: ${parseInt(UI.topBarHeight) + parseInt(UI.navBarHeight)}px;
    width: 1349px;
    @media (max-width: 1050px) {
        width: 98%;
    }
`;
const Wrapper = styled.div`
    display: flex;
`;
const Main = styled.div`
    flex: 6 1 0%;
`;

const Form = styled.form``;

const Filters = styled.div`
    display: flex;
    padding: 1px 11px;
    margin: 1px 0px;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    &:last-child {
        border-bottom: none;
    }
    @media (max-width: 642px) {
        display: block;
        padding: 12px 16px;
        margin-bottom: 22px;
        border-radius: 3px;
        box-shadow: 0px 0px 8px #dbdbdb;
    }
`;

const Textarea = styled.div`
    display: flex;
    flex: 5;
    font-family: initial;
    align-items: center;
    @media (max-width: 870px) {
        font-size: 13px;
    }
    @media (max-width: 642px) {
        display: block;
        font-size: 14px;
        width: 100%;
    }
`;

const Srno = styled.div`
    font-size: 12px;
    padding: 0;
    width: 40px;
    @media (max-width: 642px) {
        margin-left: 0px;
    }
`;

const Headingtext = styled.div`
    font-size: 13px;
    padding: 0 10px;
    margin-left: 6px;
    min-width: 100px;
    @media (max-width: 642px) {
        margin-left: 0px;
    }
`;

const Description = styled.div`
    margin-left: 6px;
    padding: 0 10px;
    font-size: 13px;
    @media (max-width: 642px) {
        margin-left: 0px;
    }
`;

const CartButton = styled.div`
    display: flex;
    flex: 3;
    justify-content: flex-end;
    @media (max-width: 642px) {
        width: 100%;
    }
`;

const Div = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

const Rupee = styled.div`
    margin-left: 6px;
    padding: 0 10px;
    font-size: 12px;
    @media (max-width: 642px) {
        width: 100%;
        margin-left: 0px;
        padding: 3px 10px;
    }
`;

const appId = "vishwa-sports-b2b-jdupr";
const appConfig = {
    id: appId,
    timeout: 10000,
};

const RealmInstance = new Realm.App(appConfig);

const Order = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const { id } = useParams();
    const order_id = id;
    console.log("order id ", id);

    const ORDER_CART = useSelector((state) => state.Order);
    const order_amount = ORDER_CART.totalAmount;

    const cartItems = useSelector((state) => {
        const transformedCartItems = [];
        for (const key in state.Order.items) {
            transformedCartItems.push({
                item_key: key,
                product_id: state.Order.items[key].product_id,
                product_name: state.Order.items[key].product_name,
                brand: state.Order.items[key].brand,
                customer_net_margin: state.Order.items[key].customer_net_margin,
                dealer_price: state.Order.items[key].dealer_price,
                discount: state.Order.items[key].discount,
                gross_margin: state.Order.items[key].gross_margin,
                gst: state.Order.items[key].gst,
                mrp: state.Order.items[key].mrp,
                order_qty: state.Order.items[key].order_qty,
                available_qty: state.Order.items[key].available_qty,
                price_after_discount:
                    state.Order.items[key].price_after_discount,
                price_by_rate_type: state.Order.items[key].price_by_rate_type,
                net_rate: state.Order.items[key].net_rate,
                price_per_product_after_gst:
                    state.Order.items[key].price_per_product_after_gst,
                rate_type: state.Order.items[key].rate_type,
                status: state.Order.items[key].status,
                total_amount_per_item_without_gst:
                    state.Order.items[key].total_amount_per_item_without_gst,
                total_amount_per_item_with_gst:
                    state.Order.items[key].total_amount_per_item_with_gst,
            });
        }
        return transformedCartItems.sort((a, b) =>
            a.product_id > b.product_id ? 1 : -1
        );
    });

    const getInputRef = (cartItems) => {
        const inputRefs = [];
        let inputCount = cartItems.length * 14;
        for (let i = 0; i < inputCount; i++) {
            inputRefs[i] = createRef(null);
        }
        return inputRefs;
    };

    const inputRef = getInputRef(cartItems);

    const onChangeOrderQty = (e, item) => {
        const available_qty = parseInt(e.target.value);
        const selectedItem = item;
        const enteredValue = e.target.value;
        if (enteredValue) {
            if (
                isNaN(enteredValue) ||
                parseFloat(enteredValue) < 0 ||
                enteredValue == undefined ||
                enteredValue == null ||
                enteredValue == ""
            ) {
                dispatch(onChangeQty({ newQty: 0, item: selectedItem }));
            } else {
                dispatch(
                    onChangeQty({ newQty: available_qty, item: selectedItem })
                );
            }
        } else {
            dispatch(onChangeQty({ newQty: 0, item: selectedItem }));
        }
    };

    const rowColor = (e, refEle) => {
        const targetedRow = refEle.current.parentNode.parentNode;
        if (e._reactName === "onFocus") {
            targetedRow.style.background = "rgb(200, 200, 200)";
        }
        if (e._reactName === "onBlur") {
            targetedRow.style.background = "#fff";
        }
    };

    const updateOrderMutation = useMutation(
        (data) => updateOrderFn(data, "updateOrder", axiosPrivate),
        {
            onSuccess: (response) => {
                // Invalidates cache and refresh

                console.log("success mutation", response);

                if (response?.status == "success") {
                    // setLoader(false);
                    enqueueSnackbar("Order updated added successfully!", {
                        variant: "success",
                    });
                    // setSubCaseType("state");
                    // reset();
                } else if (response?.response?.status === 400) {
                    // setLoader(false);
                    enqueueSnackbar("Something went wrong", {
                        variant: "error",
                    });
                } else {
                    // setLoader(false);
                    enqueueSnackbar("Something went wrong", {
                        variant: "error",
                    });
                }

                queryClient.invalidateQueries("case");
                // refetch()
            },
            onError: (error) => {
                // Invalidates cache and refresh

                console.log("error mutation ", error);

                queryClient.invalidateQueries("case");
                enqueueSnackbar("Something went wrong", { variant: "error" });
                // refetch()
            },
        }
    );

    const updateOrderHandle = () => {
        const orderObj = new Order_m();
        const newOrderItems = orderObj.getUpdateOrderItem(
            cartItems,
            order_amount,
            order_id
        );

        // console.log("newOrderItems ");

        const updateOrderBody = JSON.stringify(newOrderItems);

        updateOrderMutation.mutate(updateOrderBody);
    };

    const placeOrderHandle = () => {
        const orderObj = new Order_m();
        const newOrderItems = orderObj.placedOrder(
            cartItems,
            order_amount,
            order_id
        );

        console.log("newOrderItems");
        console.log(newOrderItems);

        const updateOrderBody = JSON.stringify(newOrderItems);
        updateOrderMutation.mutate(updateOrderBody);
    };

    const TableHead = () => {
        return (
            <thead style={{ position: "sticky", top: "115px" }}>
                <tr>
                    <th
                        style={{
                            width: 30,
                            fontSize: 12,
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Sr. No.
                    </th>
                    <th
                        style={{
                            width: 30,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Brand
                    </th>
                    <th
                        style={{
                            width: 100,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Product
                    </th>
                    <th
                        style={{
                            width: 35,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Order Qty
                    </th>
                    <th
                        style={{
                            width: 50,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Available Qty
                    </th>
                    <th
                        style={{
                            width: 75,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Rate Type
                    </th>
                    <th
                        style={{
                            width: 47,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Discount
                    </th>
                    <th
                        style={{
                            width: 60,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Price By Rate Type
                    </th>
                    <th
                        style={{
                            width: 50,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Net Rate
                    </th>
                    <th
                        style={{
                            width: 60,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Dp
                    </th>
                    <th
                        style={{
                            width: 50,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        MRP
                    </th>
                    <th
                        style={{
                            width: 50,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Gross Margin
                    </th>
                    <th
                        style={{
                            width: 50,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Gst
                    </th>
                    <th
                        style={{
                            width: 30,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Price Inc. Tax/Unit
                    </th>
                    <th
                        style={{
                            width: 50,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Net Margin
                    </th>
                    <th
                        style={{
                            width: 75,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Final Price For Order Qty
                    </th>
                    <th
                        style={{
                            width: 50,
                            fontSize: 12,
                            border: "1px solid",
                            // background: "green",
                            background: "#eee",
                        }}
                    >
                        Total
                    </th>
                </tr>
            </thead>
        );
    };

    return (
        <>
            <Navbar start={false} />
            <Container>
                <Wrapper>
                    <AccountLayout>
                        <Nav />
                        <Main>
                            <table
                                style={{
                                    borderCollapse: "collapse",
                                    fontFamily: "sans-serif",
                                    width: "100%",
                                }}
                            >
                                <TableHead />
                                <tbody>
                                    {cartItems.length > 0
                                        ? cartItems?.map((item, n) => {
                                              return (
                                                  <tr>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {n + 1}
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {item.brand}
                                                      </td>
                                                      <td
                                                          style={{
                                                              width: 150,
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {" "}
                                                          {item.product_name}
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {item.order_qty}
                                                          {/* <input
                                                              readOnly
                                                              disabled
                                                              style={{
                                                                  width: "100%",
                                                                  marginRight: 5,
                                                                  fontSize: 11,
                                                                  border: 0,
                                                              }}
                                                              placeholder="Order Qty"
                                                              type="text"
                                                              defaultValue={
                                                                  item.order_qty
                                                              }
                                                          /> */}
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              background:
                                                                  "#ddd",
                                                              border: "1px solid #eee",
                                                          }}
                                                      >
                                                          <input
                                                              style={{
                                                                  width: "100%",
                                                                  fontSize: 11,
                                                                  border: 0,
                                                                  background:
                                                                      "#ddd",
                                                              }}
                                                              placeholder="Available Qty"
                                                              type="text"
                                                              defaultValue={
                                                                  item.available_qty
                                                              }
                                                              onChange={(e) => {
                                                                  onChangeOrderQty(
                                                                      e,
                                                                      item
                                                                  );
                                                              }}
                                                              ref={
                                                                  inputRef[
                                                                      availableQtyRef(
                                                                          n
                                                                      )
                                                                  ]
                                                              }
                                                              onFocus={(e) =>
                                                                  rowColor(
                                                                      e,
                                                                      inputRef[
                                                                          availableQtyRef(
                                                                              n
                                                                          )
                                                                      ]
                                                                  )
                                                              }
                                                              onBlur={(e) =>
                                                                  rowColor(
                                                                      e,
                                                                      inputRef[
                                                                          availableQtyRef(
                                                                              n
                                                                          )
                                                                      ]
                                                                  )
                                                              }
                                                          />
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {item.rate_type}
                                                          {/* <select
                                                              style={{
                                                                  width: "90%",
                                                              }}
                                                              name="rate_type"
                                                          >
                                                              <option
                                                                  value="mrp"
                                                                  selected={
                                                                      item.rate_type ===
                                                                      "mrp"
                                                                          ? true
                                                                          : false
                                                                  }
                                                              >
                                                                  MRP
                                                              </option>
                                                              <option
                                                                  value="dp"
                                                                  selected={
                                                                      item.rate_type ===
                                                                      "dp"
                                                                          ? true
                                                                          : false
                                                                  }
                                                              >
                                                                  DP
                                                              </option>
                                                              <option
                                                                  value="dy"
                                                                  selected={
                                                                      item.rate_type ===
                                                                      "dy"
                                                                          ? true
                                                                          : false
                                                                  }
                                                              >
                                                                  Dynamic
                                                              </option>
                                                          </select> */}
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {item.discount}
                                                          {/* <input
                                                              style={{
                                                                  width: "90%",
                                                                  marginRight: 5,
                                                                  fontSize: 11,
                                                              }}
                                                              placeholder="Discount"
                                                              type="text"
                                                              defaultValue={
                                                                  item.discount
                                                              }
                                                          /> */}
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {
                                                              item.price_by_rate_type
                                                          }
                                                          {/* <input
                                                              style={{
                                                                  width: "90%",
                                                                  marginRight: 5,
                                                                  fontSize: 11,
                                                              }}
                                                              placeholder="Price By Rate Type"
                                                              type="text"
                                                              defaultValue={
                                                                  item.price_by_rate_type
                                                              }
                                                          /> */}
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {item.net_rate}
                                                          {/* <input
                                                              disabled
                                                              readOnly
                                                              style={{
                                                                  width: 60,
                                                                  marginRight: 5,
                                                                  fontSize: 11,
                                                              }}
                                                              placeholder="Net Rate"
                                                              type="text"
                                                              defaultValue={
                                                                  item.net_rate
                                                              }
                                                          /> */}
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {item.dealer_price}
                                                          {/* <input
                                                              disabled
                                                              readOnly
                                                              style={{
                                                                  width: 50,
                                                                  marginRight: 5,
                                                                  fontSize: 11,
                                                              }}
                                                              placeholder="DP"
                                                              type="text"
                                                              defaultValue={
                                                                  item.dealer_price
                                                              }
                                                          /> */}
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {item.mrp}
                                                          {/* <input
                                                              disabled
                                                              readOnly
                                                              style={{
                                                                  width: 50,
                                                                  marginRight: 5,
                                                                  fontSize: 11,
                                                              }}
                                                              placeholder="MRP"
                                                              type="text"
                                                              defaultValue={
                                                                  item.mrp
                                                              }
                                                          /> */}
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {item.gross_margin}
                                                          {/* <input
                                                              disabled
                                                              readOnly
                                                              style={{
                                                                  width: 40,
                                                                  marginRight: 5,
                                                                  fontSize: 11,
                                                              }}
                                                              placeholder="Gross Margin"
                                                              type="text"
                                                              defaultValue={
                                                                  item.gross_margin
                                                              }
                                                          /> */}
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {item.gst}
                                                          {/* <input
                                                              disabled
                                                              readOnly
                                                              style={{
                                                                  width: 30,
                                                                  marginRight: 5,
                                                                  fontSize: 11,
                                                              }}
                                                              placeholder="Gst"
                                                              type="text"
                                                              defaultValue={
                                                                  item.gst
                                                              }
                                                          /> */}
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {
                                                              item.price_per_product_after_gst
                                                          }
                                                          {/* <input
                                                              disabled
                                                              readOnly
                                                              style={{
                                                                  width: 65,
                                                                  marginRight: 5,
                                                                  fontSize: 11,
                                                              }}
                                                              placeholder="Price Inc. Tax"
                                                              type="text"
                                                              defaultValue={
                                                                  item.price_per_product_after_gst
                                                              }
                                                          /> */}
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {
                                                              item.customer_net_margin
                                                          }
                                                          {/* <input
                                                              disabled
                                                              readOnly
                                                              style={{
                                                                  width: 40,
                                                                  marginRight: 5,
                                                                  fontSize: 11,
                                                              }}
                                                              placeholder="Net Margin"
                                                              type="text"
                                                              defaultValue={
                                                                  item.customer_net_margin
                                                              }
                                                          /> */}
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                          //   key={
                                                          //       item.total_amount_per_item_with_gst
                                                          //   }
                                                      >
                                                          {
                                                              item.total_amount_per_item_with_gst
                                                          }
                                                          {/* <input
                                                              disabled
                                                              readOnly
                                                              style={{
                                                                  width: 75,
                                                                  marginRight: 5,
                                                                  fontSize: 11,
                                                              }}
                                                              placeholder="Final Price"
                                                              type="text"
                                                              defaultValue={
                                                                  item.total_amount_per_item_with_gst
                                                              }
                                                          /> */}
                                                      </td>
                                                      <td
                                                          style={{
                                                              fontSize: 12,
                                                              border: "1px solid #ddd",
                                                          }}
                                                      >
                                                          {
                                                              item.total_amount_per_item_without_gst
                                                          }
                                                          {/* <input
                                                              disabled
                                                              readOnly
                                                              style={{
                                                                  width: 50,
                                                                  marginRight: 5,
                                                                  fontSize: 11,
                                                              }}
                                                              placeholder="Total"
                                                              type="text"
                                                              defaultValue={
                                                                  item.total_amount_per_item_without_gst
                                                              }
                                                          /> */}
                                                      </td>
                                                  </tr>
                                              );
                                          })
                                        : null}
                                </tbody>
                            </table>
                            <Grid
                                style={{
                                    position: "sticky",
                                    bottom: 0,
                                    background: "#eee",
                                    padding: 5,
                                }}
                                container
                                justify="space-between"
                                alignItems="center"
                            >
                                <Grid item md={4}>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                    >
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={updateOrderHandle}
                                        >
                                            Save Changes
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item md={4}>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-start"
                                    >
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={placeOrderHandle}
                                        >
                                            Placed Order
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item md={4}>
                                    <Box
                                        display="flex"
                                        justifyContent="flex-end"
                                    >
                                        Total Amount: {order_amount}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Main>
                    </AccountLayout>
                </Wrapper>
            </Container>
            <Footer />
        </>
    );
};
export default Order;
