import { useEffect } from "react";
import * as Realm from "realm-web";

import realmApp from "../Config/Config";
import { refreshToken } from "./refreshToken";
import { axiosPrivate } from "./axiosPrivate";

const currentUser = realmApp?.currentUser;
const accessToken = currentUser?.accessToken;

const useAxiosPrivate = () => {
    useEffect(() => {
        const requestIntercept = axiosPrivate.interceptors.request.use(
            (request) => {
                if (!request.headers["Authorization"]) {
                    const tokenValue = localStorage.getItem("accessTokenValue");
                    request.headers["Authorization"] = `Bearer ${tokenValue}`;
                }
                return request;
            },
            (error) => {
                Promise.reject(error);
            }
        );

        const responseIntercept = axiosPrivate.interceptors.response.use(
            (response) => {
                console.log("its working");
                return response;
            },
            async (error) => {
                const prevRequest = error?.config;

                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true;
                    const newAccessToken = await refreshToken();
                    const newAccessTokenValue =
                        newAccessToken?.data?.access_token;
                    prevRequest.headers[
                        "Authorization"
                    ] = `Bearer ${newAccessTokenValue}`;
                    localStorage.setItem(
                        "accessTokenValue",
                        newAccessTokenValue
                    );

                    if (newAccessTokenValue != undefined) {
                        return axiosPrivate(prevRequest);
                    } else {
                        console.log(
                            "provider value ",
                            localStorage.getItem("provider")
                        );

                        if (
                            localStorage.getItem("provider") == "null" ||
                            localStorage.getItem("provider") == "undefined" ||
                            localStorage.getItem("accessTokenValue") ==
                                "undefined"
                        ) {
                            console.log("anonymous user");

                            const credentials = Realm.Credentials.anonymous();
                            const newRequest = realmApp
                                .logIn(credentials)
                                .then((res) => {
                                    prevRequest.headers[
                                        "Authorization"
                                    ] = `Bearer ${res?._accessToken}`;
                                    localStorage.setItem(
                                        "accessTokenValue",
                                        res?._accessToken
                                    );
                                    localStorage.setItem(
                                        "refreshTokenValue",
                                        res?._refreshToken
                                    );
                                    localStorage.setItem(
                                        "provider",
                                        "anon-user"
                                    );
                                    return axiosPrivate(prevRequest);
                                });
                            return newRequest;
                        } else {
                            console.log("custome tokeeen");
                        }
                    }
                }

                return Promise.reject(error);
            }
        );

        return () => {
            axiosPrivate.interceptors.request.eject(requestIntercept);
            axiosPrivate.interceptors.response.eject(responseIntercept);
        };
    }, [refreshToken]);

    return axiosPrivate;
};

export default useAxiosPrivate;

//anony
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiYWFzX2RldmljZV9pZCI6IjY0OTQ0ZmUyYjQ1NTJlMzI5MDA3NmE0OSIsImJhYXNfZG9tYWluX2lkIjoiNjQ0ZmQ3NWNlZmYxOWVkNDVhODUxY2ZkIiwiZXhwIjoxNjg3NDQzMTc4LCJpYXQiOjE2ODc0NDEzNzgsImlzcyI6IjY0OTQ0ZmUyYjQ1NTJlMzI5MDA3NmE0YiIsInN0aXRjaF9kZXZJZCI6IjY0OTQ0ZmUyYjQ1NTJlMzI5MDA3NmE0OSIsInN0aXRjaF9kb21haW5JZCI6IjY0NGZkNzVjZWZmMTllZDQ1YTg1MWNmZCIsInN1YiI6IjY0OTQ0ZmUyYjQ1NTJlMzI5MDA3NmE0NSIsInR5cCI6ImFjY2VzcyJ9.CMHJl2GXed1M04t-ZZbirCmeg1B6t0UzF7JHrz-all4
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiYWFzX2RhdGEiOm51bGwsImJhYXNfZGV2aWNlX2lkIjoiNjQ5NDRmZTJiNDU1MmUzMjkwMDc2YTQ5IiwiYmFhc19kb21haW5faWQiOiI2NDRmZDc1Y2VmZjE5ZWQ0NWE4NTFjZmQiLCJiYWFzX2lkIjoiNjQ5NDRmZTJiNDU1MmUzMjkwMDc2YTRiIiwiYmFhc19pZGVudGl0eSI6eyJpZCI6IjY0OTQ0ZmUyYjQ1NTJlMzI5MDA3NmE0NC10dXJyZW9nYWp0bHpuZ2dibWFycmN5cHMiLCJwcm92aWRlcl90eXBlIjoiYW5vbi11c2VyIiwicHJvdmlkZXJfaWQiOiI2NDRmZGEyM2VmZjE5ZWQ0NWE4NmRmMTIifSwiZXhwIjozMjY0MjQxMzc4LCJpYXQiOjE2ODc0NDEzNzgsInN0aXRjaF9kYXRhIjpudWxsLCJzdGl0Y2hfZGV2SWQiOiI2NDk0NGZlMmI0NTUyZTMyOTAwNzZhNDkiLCJzdGl0Y2hfZG9tYWluSWQiOiI2NDRmZDc1Y2VmZjE5ZWQ0NWE4NTFjZmQiLCJzdGl0Y2hfaWQiOiI2NDk0NGZlMmI0NTUyZTMyOTAwNzZhNGIiLCJzdGl0Y2hfaWRlbnQiOnsiaWQiOiI2NDk0NGZlMmI0NTUyZTMyOTAwNzZhNDQtdHVycmVvZ2FqdGx6bmdnYm1hcnJjeXBzIiwicHJvdmlkZXJfdHlwZSI6ImFub24tdXNlciIsInByb3ZpZGVyX2lkIjoiNjQ0ZmRhMjNlZmYxOWVkNDVhODZkZjEyIn0sInN1YiI6IjY0OTQ0ZmUyYjQ1NTJlMzI5MDA3NmE0NSIsInR5cCI6InJlZnJlc2gifQ.GR2a-W-gTzT_o41AXhD-l35KkF7-CuiDh2NccVvnY1k

// custom
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiYWFzX2RldmljZV9pZCI6IjY0OTQ1MDE4MzIyNDk3NTkwMWRlNzJjMCIsImJhYXNfZG9tYWluX2lkIjoiNjQ0ZmQ3NWNlZmYxOWVkNDVhODUxY2ZkIiwiZXhwIjoxNjg3NDQzMjMyLCJpYXQiOjE2ODc0NDE0MzIsImlzcyI6IjY0OTQ1MDE4MzIyNDk3NTkwMWRlNzJjNCIsInN0aXRjaF9kZXZJZCI6IjY0OTQ1MDE4MzIyNDk3NTkwMWRlNzJjMCIsInN0aXRjaF9kb21haW5JZCI6IjY0NGZkNzVjZWZmMTllZDQ1YTg1MWNmZCIsInN1YiI6IjY0ODgwODYzYjVlNTFiNmUxYWU1NmE5NCIsInR5cCI6ImFjY2VzcyJ9.FjBxzyMBTCF7NnKCYysB3Lqzry5a0A08eK71wcJ8mtw
// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiYWFzX2RhdGEiOm51bGwsImJhYXNfZGV2aWNlX2lkIjoiNjQ5NDUwMTgzMjI0OTc1OTAxZGU3MmMwIiwiYmFhc19kb21haW5faWQiOiI2NDRmZDc1Y2VmZjE5ZWQ0NWE4NTFjZmQiLCJiYWFzX2lkIjoiNjQ5NDUwMTgzMjI0OTc1OTAxZGU3MmM0IiwiYmFhc19pZGVudGl0eSI6eyJpZCI6Ik82N2RBZTNISmhhbmU0Q1lXOVQ3QUE2Q0I5aTEiLCJwcm92aWRlcl90eXBlIjoiY3VzdG9tLXRva2VuIiwicHJvdmlkZXJfaWQiOiI2NDg2YmI2ZmZjMWE2MGRkNmRmNzZlZjIifSwiZXhwIjoxNjkyNjI1NDMyLCJpYXQiOjE2ODc0NDE0MzIsInN0aXRjaF9kYXRhIjpudWxsLCJzdGl0Y2hfZGV2SWQiOiI2NDk0NTAxODMyMjQ5NzU5MDFkZTcyYzAiLCJzdGl0Y2hfZG9tYWluSWQiOiI2NDRmZDc1Y2VmZjE5ZWQ0NWE4NTFjZmQiLCJzdGl0Y2hfaWQiOiI2NDk0NTAxODMyMjQ5NzU5MDFkZTcyYzQiLCJzdGl0Y2hfaWRlbnQiOnsiaWQiOiJPNjdkQWUzSEpoYW5lNENZVzlUN0FBNkNCOWkxIiwicHJvdmlkZXJfdHlwZSI6ImN1c3RvbS10b2tlbiIsInByb3ZpZGVyX2lkIjoiNjQ4NmJiNmZmYzFhNjBkZDZkZjc2ZWYyIn0sInN1YiI6IjY0ODgwODYzYjVlNTFiNmUxYWU1NmE5NCIsInR5cCI6InJlZnJlc2gifQ.sL4WiHI3tQ6SJvIs_kW7d57mcuSyzcQGiIQLFD1JxT0
