// import Announcement from '../../Components/Announcement/Announcement'
import {
    useQuery,
    useMutation,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import Poster from "../../Components/Poster/Poster";
import Menubar from "../../Components/Menubar/Menubar";
import Footer from "../../Components/Layout/Footer";
import AppSection from "../../Components/AppSection/AppSection";
import Topbar from "../../Components/Topbar/Topbar";
import styled from "styled-components";
// import { pricelist } from "../../Data/Pricelist";

import { getBrandFn } from "../../Api/Index";
import { ROUTES } from "../../Routes/Route";
import SkeletonCard from "../../Components/Skeleton/BrandSkeleton";

import useAxiosPrivate from "../../Api/useAxiosPrivate";

const { innerWidth: width, innerHeight: height } = window;

const Container = styled.div`
    width: 1500px;
    margin: auto;
    padding: 0px 22px;
    @media (max-width: 1500px) {
        width: 98%;
        padding: 0px 12px;
    }
    @media (max-width: 1050px) {
        width: 96%;
        padding: 0px 0px;
    }
    @media (max-width: 767px) {
        width: 93%;
        padding: 0px 13px;
    }
`;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 1.5rem;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    @media (max-width: 1050px) {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
    @media (max-width: 767px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 10px;
    }
`;
const ImaContainer = styled.a`
    border: 1px solid #eaeaea;
    border-radius: 0.375rem;
`;

const Image = styled.img`
    width: 100%;
`;
const ImgName = styled.h1`
    font-size: 1rem;
    line-height: 1.5rem;
    color: #000;
    text-align: center;
`;

const Heading = styled.h1`
    font-size: 3rem;
    font-weight: 600;
    margin-top: 70px;
    margin-bottom: 30px;
`;
const Moboimage = styled.div`
    height: 100%;
    width: 100%;
`;

const PriceList = () => {
    const axiosPrivate = useAxiosPrivate();

    const imgUrl =
        "https://vishwasports.s3.ap-south-1.amazonaws.com/banner/PRICE LIST CATALOG.png";
    const mobo_url =
        "https://vishwasports.s3.ap-south-1.amazonaws.com/banner/PRICE LIST+MOBILE.png";

    const queryKey = "brand";
    const brand_url = `${ROUTES.getBrand}`;

    // console.log("currentUser ", currentUser);

    const queryConfig = {
        refetchOnWindowFocus: false,
        // staleTime: 50000,
        // cacheTime: 10000,
        keepPreviousData: true,
    };

    const {
        data: brandList,
        isLoading,
        isFetching,
        isError,
        refetch,
    } = useQuery([queryKey, brand_url, axiosPrivate], getBrandFn, queryConfig);
    const brand = brandList?.data;

    console.log("brand ", brand);

    return (
        <>
            <Topbar />
            <Menubar />
            {width > 767 ? (
                <Poster url={imgUrl} />
            ) : (
                <Moboimage>
                    <Image src={mobo_url} />
                </Moboimage>
            )}

            <Container>
                <Heading>Pricelist</Heading>
                <Wrapper>
                    {isLoading ? (
                        <SkeletonCard limit={20} />
                    ) : (
                        brand?.map((item, index) => (
                            // <a href={item.pricelist_pdf} style={{textDecoration:"none"}} rel="noreferrer">
                            <ImaContainer
                                target="_blank"
                                href={item.pricelist_pdf}
                                style={{ textDecoration: "none" }}
                                rel="noreferrer"
                            >
                                <div>
                                    <Image
                                        src={item.image.desktop.url}
                                        quality="100"
                                        alt={item.brand_name}
                                    />
                                </div>
                                <div>
                                    <ImgName>{item.brand_name}</ImgName>
                                </div>
                            </ImaContainer>
                            // </a>
                        ))
                    )}
                </Wrapper>
            </Container>
            <AppSection />
            <Footer />
        </>
    );
};

export default PriceList;
