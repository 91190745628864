import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Products from "../../pages/Search/Products";
import Filter from "../../pages/Search/Filter";

const FilterWrapper = styled.div`
    margin: 0px auto;
`;
const Wrapper = styled.div`
    height: 50px;
    color: rgb(0, 0, 0);
    background-color: rgb(247, 247, 247);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 1px 0px;
    // z-index: 1;
`;
const WrapperCtn = styled.div`
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 1px;
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
`;
const WrapText = styled.div`
    flex: 1 1 0%;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    color: rgb(0, 0, 0);
    font-weight: 500;
`;
const Space = styled.div`
    width: 1px;
    height: 100%;
    @media (max-width: 767px) {
        width: 76px;
    }
`;
const Span = styled.span`
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    flex-shrink: 0;
    height: 18px;
`;
const Image = styled.img``;
const WrapperCount = styled.div`
    max-width: 767px;
    left: 0px;
    width: 100%;
    margin: auto;
    position: fixed;
    top: 0px;
    background: rgb(255, 255, 255);
    height: 100%;
    z-index: 1111;
    display: ${(props) => (props.clicked ? "block" : "none")};
`;
const Searcharea = styled.div`
    width: 100%;
    background: white;
    margin: 6px 4px;
    background: white;
    padding: 18px;
    border-radius: 4px;
    @media (max-width: 767px) {
        width: 93%;
        display: ${(props) => (props.clicked ? "none" : "block")};
    }
    @media (max-width: 511px) {
        width: 90%;
    }
`;

const Filterarea = () => {
    const [clicked, setClicked] = useState(false);

    const itemClicked = () => {
        setClicked(!clicked);
    };

    console.log("clicked", clicked);

    return (
        <>
            <FilterWrapper>
                <Wrapper>
                    <WrapperCtn onClick={itemClicked}>
                        <WrapText>
                            <Span>
                                <Image src="https://img.icons8.com/metro/26/null/sorting-answers.png" />
                            </Span>
                            Sort
                        </WrapText>
                        <Space></Space>
                        <WrapText>
                            <Span>
                                <Image src="https://img.icons8.com/external-febrian-hidayat-basic-outline-febrian-hidayat/24/null/external-sort-user-interface-febrian-hidayat-basic-outline-febrian-hidayat.png" />
                            </Span>
                            Filter.
                        </WrapText>
                    </WrapperCtn>
                </Wrapper>
            </FilterWrapper>
        </>
    );
};

export default Filterarea;
