export const ADD_TO_CART = "ADD_TO_CART";
export const INCREASE_QTY = "INCREASE_QTY";
export const DECREASE_QTY = "DECREASE_QTY";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CHANGE_QTY = "CHANGE_QTY";

export const addToCart = (product, quantity, mrp, dealer_price, gst) => {
    return {
        type: ADD_TO_CART,
        product: product,
        quantity: quantity,
        mrp: mrp,
        dealer_price: dealer_price,
        gst: gst,
    };
};

export const increaseQty = (product_id, newQty) => {
    return { type: INCREASE_QTY, product_id: product_id, newQty: newQty };
};

export const decreaseQty = (product_id, newQty) => {
    return { type: DECREASE_QTY, product_id: product_id, newQty: newQty };
};

export const removeFromCart = (product_id) => {
    return { type: REMOVE_FROM_CART, product_id: product_id };
};

export const changeQty = (product_id, newQty) => {
    return { type: CHANGE_QTY, product_id: product_id, newQty: newQty };
};
