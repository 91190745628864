class CartItem {
    constructor(
        productId,
        brandName,
        product_name,
        order_quantity,
        mrp,
        dealer_price,
        gst,
        netPrice
    ) {
        this.productId = productId;
        this.brandName = brandName;
        this.product_name = product_name;
        this.orderQuantity = order_quantity;
        this.mrp = parseFloat(mrp);
        this.dealerPrice = parseFloat(dealer_price);
        this.gst = parseFloat(gst);
        this.netPrice = netPrice;
    }
}

export default CartItem;
