export const productList = [
    {
        id:1,
        product_name:"KASHMIR WILLOW CRICKET BAT 99",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:4029,
        brand:"SG"
    },
    {
        id:2,
        product_name:"KASHMIR WILLOW CRICKET BAT 88",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3779,
        brand:"SG"
    },
    {
        id:3,
        product_name:"KASHMIR WILLOW CRICKET BAT 77",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3629,
        brand:"SG"
    },
    {
        id:4,
        product_name:"KASHMIR WILLOW CRICKET BAT 66",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3379,
        brand:"SG"
    },
    {
        id:5,
        product_name:"KASHMIR WILLOW CRICKET BAT 55",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3229,
        brand:"SG"
    },
    {
        id:6,
        product_name:"KASHMIR WILLOW CRICKET BAT 44",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:2979,
        brand:"SG"
    },
    {
        id:7,
        product_name:"KASHMIR WILLOW CRICKET BAT 33",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:4029,
        brand:"SG"
    },
    {
        id:8,
        product_name:"KASHMIR WILLOW CRICKET BAT 22",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3779,
        brand:"SG"
    },
    {
        id:9,
        product_name:"KASHMIR WILLOW CRICKET BAT 77",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3629,
        brand:"SG"
    },
    {
        id:10,
        product_name:"KASHMIR WILLOW CRICKET BAT 66",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3379,
        brand:"SG"
    },
    {
        id:11,
        product_name:"KASHMIR WILLOW CRICKET BAT 55",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3229,
        brand:"SG"
    },
    {
        id:12,
        product_name:"KASHMIR WILLOW CRICKET BAT 44",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:2979,
        brand:"SG"
    },
    {
        id:13,
        product_name:"KASHMIR WILLOW CRICKET BAT 99",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:4029,
        brand:"SG"
    },
    {
        id:14,
        product_name:"KASHMIR WILLOW CRICKET BAT 88",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3779,
        brand:"SG"
    },
    {
        id:15,
        product_name:"KASHMIR WILLOW CRICKET BAT 77",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3629,
        brand:"SG"
    },
    {
        id:16,
        product_name:"KASHMIR WILLOW CRICKET BAT 66",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3379,
        brand:"SG"
    },
    {
        id:17,
        product_name:"KASHMIR WILLOW CRICKET BAT 55",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3229,
        brand:"SG"
    },
    {
        id:18,
        product_name:"KASHMIR WILLOW CRICKET BAT 44",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:2979,
        brand:"SG"
    },
    {
        id:19,
        product_name:"KASHMIR WILLOW CRICKET BAT 99",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:4029,
        brand:"SG"
    },
    {
        id:20,
        product_name:"KASHMIR WILLOW CRICKET BAT 88",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3779,
        brand:"SG"
    },
    {
        id:21,
        product_name:"KASHMIR WILLOW CRICKET BAT 77",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3629,
        brand:"SG"
    },
    {
        id:22,
        product_name:"KASHMIR WILLOW CRICKET BAT 66",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3379,
        brand:"SG"
    },
    {
        id:23,
        product_name:"KASHMIR WILLOW CRICKET BAT 55",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:3229,
        brand:"SG"
    },
    {
        id:24,
        product_name:"KASHMIR WILLOW CRICKET BAT 44",
        image_url:"https://cdnmedia.dsc-cricket.com/media/catalog/product/cache/2402dd5b619382dc4aa18cf120f2b3ca/s/p/spliit-pro-english-willow-cricket-bat_1.jpg",
        sale_price:1223.2,
        discount_percent:29,
        net_price:2979,
        brand:"SG"
    }
]