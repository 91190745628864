import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styled from "styled-components";

const Container = styled.div`
    width: 100%;
`;

const Image = styled.img`
    height: 660px;
    width: 100%;
    @media (max-width: 767px) {
        height: 300px;
    }
    @media (max-width: 600px) {
        height: 250px;
    }
`;

const Carousal = (props) => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        pauseOnHover: false,
    };

    const imgUrl1 =
        window.innerWidth > 767
            ? "https://vishwasports.s3.ap-south-1.amazonaws.com/carousal/banner-1.jpg"
            : "https://vishwasports.s3.ap-south-1.amazonaws.com/carousal/banner-mobile-1.png";
    const imgUrl2 =
        window.innerWidth > 767
            ? "https://vishwasports.s3.ap-south-1.amazonaws.com/carousal/banner-2.jpg"
            : "https://vishwasports.s3.ap-south-1.amazonaws.com/carousal/banner-mobile-2.png";
    const imgUrl3 =
        window.innerWidth > 767
            ? "https://vishwasports.s3.ap-south-1.amazonaws.com/carousal/banner-3.png"
            : "https://vishwasports.s3.ap-south-1.amazonaws.com/carousal/banner-mobile-3.png";
    const imgUrl4 =
        window.innerWidth > 767
            ? "https://vishwasports.s3.ap-south-1.amazonaws.com/carousal/banner-4.jpg"
            : "https://vishwasports.s3.ap-south-1.amazonaws.com/carousal/banner-mobile-4.png";

    return (
        <Container>
            <Slider {...settings}>
                <Image src={imgUrl1} />
                <Image src={imgUrl2} />
                <Image src={imgUrl3} />
                <Image src={imgUrl4} />
            </Slider>
        </Container>
    );
};

export default Carousal;
