import { roundOffZero, upperCaseTxt } from "../Constants/Index";
import lodash from "lodash";

class Product_m {
    constructor(
        productId,
        brandname,
        productName,
        dealerPrice,
        mrpPrice,
        gst,
        isAddedToCart
    ) {
        this.productId = productId;
        this.brandname = brandname;
        this.productName = productName;
        this.dealerPrice = dealerPrice;
        this.mrpPrice = mrpPrice;
        this.gst = gst;
        this.isAddedToCart = isAddedToCart;
    }

    get getProduct() {
        if (
            this.productId == undefined ||
            this.productId == null ||
            this.productId == ""
        ) {
            return;
        } else {
            return {
                _id: this.productId,
                brand_name: this.brandname,
                dealer_price: this.dealerPrice,
                product_name: this.productName,
                mrp: this.mrpPrice,
            };
        }
    }

    get getProductId() {
        if (
            this.productId == undefined ||
            this.productId == null ||
            this.productId == ""
        ) {
            return;
        } else {
            return this.productId;
        }
    }

    get getBrandName() {
        if (
            this.brandname == undefined ||
            this.brandname == null ||
            this.brandname == ""
        ) {
            return;
        } else {
            const c = lodash.upperCase(this.brandname);
            return upperCaseTxt(c);
        }
    }

    get getProductName() {
        if (
            this.productName == undefined ||
            this.productName == null ||
            this.productName == ""
        ) {
            return;
        } else {
            const c = lodash.upperCase(this.productName);
            return upperCaseTxt(c);
        }
    }

    get getDealerPrice() {
        if (
            this.dealerPrice == undefined ||
            this.dealerPrice == null ||
            this.dealerPrice == ""
        ) {
            return;
        } else {
            return this.discount == 0 ? 0 : roundOffZero(this.dealerPrice);
        }
    }

    get getmrpPrice() {
        if (
            this.mrpPrice == undefined ||
            this.mrpPrice == null ||
            this.mrpPrice == ""
        ) {
            return 0;
        } else {
            return roundOffZero(this.mrpPrice);
        }
    }

    get getGst() {
        if (this.gst == undefined || this.gst == null || this.gst == "") {
            return 0;
        } else {
            return roundOffZero(this.gst);
        }
    }

    get getIsAddedToCart() {
        if (
            this.isAddedToCart == undefined ||
            this.isAddedToCart == null ||
            this.isAddedToCart == ""
        ) {
            return false;
        } else {
            return this.isAddedToCart;
        }
    }

    //CLASS ENDS
}

export default Product_m;
