import axios from "axios";
export const base_url = `https://ap-south-1.aws.data.mongodb-api.com/app/vishwa-sports-b2b-jdupr/endpoint`;
const header = {
	"Content-Type": "application/json",
};

export const axiosPrivate = axios.create({
	baseURL: base_url,
	headers: header,
});
