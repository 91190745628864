import {
    ADD_ORDER_ITEM_TO_CART_TO_UPDATE,
    ON_CHANGE_QTY,
} from "../../Action/Order/OrderAction";

import Order_m from "../../../Models/Order_m";
const orderObj = new Order_m();

const initialState = {
    items: {},
    totalAmount: 0,
};

export default function orderReducer(state = initialState, action) {
    let selectedItem;
    let newQty = 0;
    let updatedCartItems;

    switch (action.type) {
        case ADD_ORDER_ITEM_TO_CART_TO_UPDATE:
            selectedItem = action.payload;
            const current_order_amount = selectedItem.order_amount;
            const items = selectedItem.order_items;
            const result = items?.reduce(
                (acc, obj) => ({ ...acc, [obj.product_id]: obj }),
                {}
            );
            return {
                ...state,
                items: result,
                totalAmount: current_order_amount,
            };

        case ON_CHANGE_QTY:
            selectedItem = action.payload.item;
            newQty = action.payload.newQty;
            const mrp = selectedItem.mrp;
            const old_total_amount_of_all_item = state.totalAmount;
            const old_total_amount_per_item_without_gst =
                selectedItem.total_amount_per_item_without_gst;
            const new_total_amount_per_item_without_gst =
                orderObj.caculatePriceWithoutTax(mrp, newQty);

            const newTotalAmount = orderObj.calculateTotalAmount(
                old_total_amount_of_all_item,
                old_total_amount_per_item_without_gst,
                new_total_amount_per_item_without_gst
            );

            const updatedItem = orderObj.whenAvailableQtyChange(
                newQty,
                selectedItem
            );

            updatedCartItems = {
                ...state.items,
                [selectedItem.product_id]: updatedItem,
            };

            return {
                ...state,
                items: updatedCartItems,
                totalAmount: newTotalAmount,
            };

        default:
            return state;
        // SWITCH ENDS
    }
}
