import { useEffect } from "react";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Menubar from "../../Components/Menubar/Menubar";
import Topbar from "../../Components/Topbar/Topbar";
import Footer from "../../Components/Layout/Footer";

const Container = styled.div`
    margin: auto;
    width: 1050px;
    padding: 26px 0px;
    @media (max-width: 1050px) {
        width: 94%;
    }
`;
const Wrapper = styled.div`
    padding: 0px 33px;
    @media (max-width: 447px) {
        padding: 0px 12px;
    }
`;

const Section = styled.div`
    margin-bottom: 4px;
`;

const ContentCtn = styled.div``;
const Title = styled.h1`
    font-size: 2rem;
    font-weight: 600;
    padding-top: 28px;
    @media (max-width: 447px) {
        font-size: 26px;
    }
`;
const Content = styled.div`
    font-size: 15px;
    margin-top: 7px;
    @media (max-width: 447px) {
        font-size: 14px;
    }
`;

const AboutPage = () => {
    const data = [
        {
            id: 1,
            title: " About Vishwa",
            content: " Vishwa Sport Co. is sports brand located in Meerut",
        },
    ];

    return (
        <>
            <Topbar />
            <Menubar />
            <Container>
                <Wrapper>
                    {data?.map((item, index) => {
                        return (
                            <Section>
                                <Title>{item.title}</Title>
                                <Content>{item.content}</Content>
                            </Section>
                        );
                    })}
                </Wrapper>
            </Container>
            <Footer />
        </>
    );
};

export default AboutPage;
