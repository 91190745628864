import {
    ADD_TO_CART,
    INCREASE_QTY,
    DECREASE_QTY,
    REMOVE_FROM_CART,
    CHANGE_QTY,
} from "../../Action/Cart/CartAction";

import CartItem from "../../../Models/CartItem";
import { roundOffZero } from "../../../Constants/Index";

const initialState = {
    items: {},
    totalAmount: 0,
};

export default function cartReducer(state = initialState, action) {
    let product_id,
        brand_name,
        product_name,
        finalQtyPerProduct = 0,
        productMrp = 0,
        totalPricePerProduct = 0,
        cartItems;
    let updatedCartItems;
    let selectedCartItem;
    let currentQty = 0;
    let newQty = 0;
    let finalQty = 0;
    let pricePerProduct = 0;
    let currentTotalAmount = 0;
    let newTotalAmount;
    let total_amount = 0;
    let oldTotalPricePerProduct = 0;
    let netMrpPrice = 0;
    let qtyToDeduct = 0;
    let productGst = 0;
    let dealerPrice = 0;

    switch (action.type) {
        case ADD_TO_CART:
            const addedProduct = action.product;
            const qty = parseInt(action.quantity);
            const mrp = parseFloat(action.mrp).toFixed(2);
            const dealer_price = Number(
                parseFloat(action.dealer_price).toFixed(2)
            );
            const gst = Number(parseFloat(action.gst).toFixed(2));
            currentTotalAmount = parseFloat(state.totalAmount);
            pricePerProduct = qty * mrp;
            newTotalAmount = Number(
                parseFloat(currentTotalAmount) + parseFloat(pricePerProduct)
            );

            console.log("reducer dp 2", dealer_price);

            pricePerProduct = parseInt(qty) * parseInt(mrp);

            if (state.items[addedProduct._id]) {
                // ALREADY EXISTS
                console.log("producct already exists");
            } else {
                // NEW PRODUCT
                console.log("New product");
                cartItems = new CartItem(
                    addedProduct._id,
                    addedProduct.brand_name,
                    addedProduct.product_name,
                    qty,
                    mrp,
                    dealer_price,
                    gst,
                    pricePerProduct
                );

                updatedCartItems = {
                    ...state.items,
                    [addedProduct._id]: cartItems,
                };
                total_amount = newTotalAmount;
            }

            return {
                ...state,
                items: updatedCartItems,
                totalAmount: total_amount,
            };

        case INCREASE_QTY:
            console.log("hello jk");
            selectedCartItem = state.items[action.product_id];
            currentQty = selectedCartItem.orderQuantity;
            currentTotalAmount = state.totalAmount;
            productMrp = selectedCartItem.mrp;
            dealerPrice = selectedCartItem.dealerPrice;
            productGst = selectedCartItem.gst;
            newQty = action.newQty == "" ? 1 : parseInt(action.newQty);
            finalQty = currentQty + newQty;

            if (currentQty > 0) {
                totalPricePerProduct =
                    parseInt(finalQty) * parseInt(productMrp);
                newTotalAmount =
                    currentTotalAmount -
                    parseInt(currentQty) * parseInt(productMrp) +
                    parseInt(finalQty) * parseInt(productMrp);

                cartItems = new CartItem(
                    selectedCartItem.productId,
                    selectedCartItem.brandName,
                    selectedCartItem.product_name,
                    finalQty,
                    productMrp,
                    dealerPrice,
                    productGst,
                    totalPricePerProduct
                );

                updatedCartItems = {
                    ...state.items,
                    [action.product_id]: cartItems,
                };
                total_amount = newTotalAmount;
            }

            return {
                ...state,
                items: updatedCartItems,
                totalAmount: total_amount,
            };

        case DECREASE_QTY:
            selectedCartItem = state.items[action.product_id];
            currentQty = selectedCartItem.orderQuantity;
            currentTotalAmount = state.totalAmount;
            productMrp = selectedCartItem.mrp;
            dealerPrice = selectedCartItem.dealerPrice;
            productGst = selectedCartItem.gst;
            newQty = action.newQty;

            if (currentQty > 1) {
                finalQty = currentQty - newQty;
                totalPricePerProduct = productMrp * finalQty;
                netMrpPrice = finalQty * productMrp;
                newTotalAmount =
                    currentTotalAmount -
                    parseInt(currentQty) * parseInt(productMrp) +
                    parseInt(finalQty) * parseInt(productMrp);

                cartItems = new CartItem(
                    selectedCartItem.productId,
                    selectedCartItem.brandName,
                    selectedCartItem.product_name,
                    finalQty,
                    productMrp,
                    dealerPrice,
                    productGst,
                    totalPricePerProduct
                );

                updatedCartItems = {
                    ...state.items,
                    [action.product_id]: cartItems,
                };
                total_amount = newTotalAmount;
            } else {
                updatedCartItems = {
                    ...state.items,
                };
                total_amount = currentTotalAmount;
            }

            return {
                ...state,
                items: updatedCartItems,
                totalAmount: total_amount,
            };

        case REMOVE_FROM_CART:
            selectedCartItem = state.items[action.product_id];
            currentTotalAmount = state.totalAmount;
            totalPricePerProduct = selectedCartItem.netPrice;
            productMrp = selectedCartItem.mrp;
            dealerPrice = selectedCartItem.dealerPrice;
            productGst = selectedCartItem.gst;
            newTotalAmount = currentTotalAmount - totalPricePerProduct;
            updatedCartItems = { ...state.items };

            delete updatedCartItems[action.product_id];

            return {
                ...state,
                items: updatedCartItems,
                totalAmount: newTotalAmount,
            };

        case CHANGE_QTY:
            selectedCartItem = state.items[action.product_id];
            currentQty = selectedCartItem.quantity;
            newQty = action.newQty;
            productMrp = selectedCartItem.mrp;

            oldTotalPricePerProduct = currentQty * productMrp;
            currentTotalAmount = state.totalAmount;

            console.log("reducer ");
            console.log(selectedCartItem);
            if (newQty > currentQty) {
                finalQty = parseInt(newQty);
                totalPricePerProduct = newQty * productMrp;

                newTotalAmount =
                    currentTotalAmount -
                    oldTotalPricePerProduct +
                    totalPricePerProduct;

                cartItems = new CartItem(
                    selectedCartItem.productId,
                    selectedCartItem.brandName,
                    selectedCartItem.product_name,
                    finalQty,
                    productMrp,
                    totalPricePerProduct
                );

                updatedCartItems = {
                    ...state.items,
                    [action.product_id]: cartItems,
                };
                total_amount = newTotalAmount;
            } else if (newQty < currentQty) {
                finalQty = parseInt(newQty);
                qtyToDeduct = currentQty - newQty;

                totalPricePerProduct =
                    selectedCartItem.netPrice - qtyToDeduct * productMrp;

                newTotalAmount = currentTotalAmount - qtyToDeduct * productMrp;

                console.log("finalQty ", finalQty);

                cartItems = new CartItem(
                    selectedCartItem.productId,
                    selectedCartItem.brandName,
                    selectedCartItem.product_name,
                    finalQty,
                    productMrp,
                    totalPricePerProduct
                );

                updatedCartItems = {
                    ...state.items,
                    [action.product_id]: cartItems,
                };
                total_amount = newTotalAmount;
            }

            return {
                ...state,
                items: updatedCartItems,
                totalAmount: total_amount,
            };

        default:
            return state;
        // SWITCH ENDS
    }
}
