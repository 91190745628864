import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Menubar from "../../Components/Menubar/Menubar";
import Topbar from "../../Components/Topbar/Topbar";
import { FilterSharp } from "@mui/icons-material";

import app from "../../Config/Firebase";
import * as Realm from "realm-web";
import {
    getAuth,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    onAuthStateChanged,
} from "firebase/auth";

import { setLoggedUser } from "../../Store/Action/Index";

const Loginpage = styled.div`
    max-width: 370px;
    margin: 33px auto;
    @media (max-width: 1050px) {
        width: 98%;
    }
`;
const Form = styled.form`
    margin: auto;
    background: #eee;
    border-radius: 5px;
    padding: 16px 22px;
    @media (max-width: 739px) {
        width: 64%;
    }
    @media (max-width: 442px) {
        width: 82%;
    }
`;
const Para = styled.div`
    margin: 0px;
    text-align: center;
    font-size: 17px;
    padding-bottom: 18px;
    @media (max-width: 492px) {
        font-size: 12px;
    }
`;
const Input = styled.input`
    width: 98%;
    padding: 3px 5px;
    border: 1px solid #c8c8c8;
    height: 36px;
    font-size: 15px;
    border-radius: 4px;
    &::placeholder {
        color: #c8c8c8;
        opacity: 1;
    }
    &:focus {
        outline: none;
        box-shadow: 0px 0px 2px red;
    }
    &:: -webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
const Icon = styled.div`
    position: absolute;
    right: 4px;
    top: 12px;
    width: 17px;
`;
const Label = styled.label`
    margin-top: 8px;
    margin-bottom: 10px;
`;

const Heading = styled.h2`
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 3px;
    margin-top: 15px;
`;
const Text = styled.div`
    justify-content: center;
    display: flex;
    margin-top: 14px;
    @media (max-width: 631px) {
        font-size: 13px;
    }
    @media (max-width: 350px) {
        font-size: 11px;
    }
`;
const Bottomtext = styled.div`
    margin-right: 8px;
`;
const Btn = styled.div`
    margin: 16px 0px;
    width: 100%;
`;
const Button = styled.button`
    display: block;
    color: white;
    font-size: 13px;
    padding: 12px 22px;
    background: rgb(19, 19, 18);
    text-align: center;
    border-radius: 5px;
    box-shadow: 3px 3px 2px #cdc9c9;
    &:hover {
        background: rgb(65, 65, 64);
    }
`;
const TopimgAnchor = styled.a`
    display: block;
    text-align: center;
    padding-bottom: 10px;
`;
const Image = styled.img`
    width: 120px;
`;
const BtntagAnchor = styled.a`
    color: black;
    font-size: 14px;
`;
const Iconctn = styled.div`
    position: relative;
`;

const logo = "https://vishwasports.s3.ap-south-1.amazonaws.com/gravity.png";

const appId = "vishwa-sports-b2b-jdupr";
const appConfig = {
    id: appId,
    timeout: 10000,
};

const auth = getAuth(app);
const RealmInstance = new Realm.App(appConfig);

const Login = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    console.log("params ", searchParams.get("return_url"));
    const return_url = searchParams.get("return_url");

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const [user, setUser] = useState(null);

    const USER_AUTH = useSelector((state) => state.Auth);

    const isAuthorised = USER_AUTH.isAuthorised;

    const [isOTPSent, setIsOTPSent] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [message, setMessage] = useState("");

    const loginRealmWithJwtToken = async (jwtToken) => {
        await RealmInstance.currentUser?.logOut();
        const credentials = Realm.Credentials.jwt(jwtToken);
        try {
            // localStorage.clear();
            const user = await RealmInstance.logIn(credentials);
            localStorage.setItem("provider", "custom-token");
            localStorage.setItem("accessTokenValue", user?.accessToken);
            localStorage.setItem("refreshTokenValue", user?.refreshToken);
            dispatch(setLoggedUser(user));
            return user;
        } catch (err) {
            console.error("Failed to log in", err.message);
        }
    };

    // useEffect(() => {
    //     onAuthStateChanged(auth, (user) => {
    //         if (user) {
    //             user.getIdToken().then(function (idToken) {
    //                 loginRealmWithJwtToken(idToken);
    //             });
    //         } else {
    //             console.log("user is logged out");
    //         }
    //     });

    //     console.log("provider 1", localStorage.getItem("provider"));
    // }, [isAuthorised]);

    // console.log("RealmInstance ", RealmInstance?.currentUser);

    function onSubmit(input, e) {
        console.log("input ", input);
        setShowLoader(true);
        if (isOTPSent) {
            console.log("verifying 1");
            onVerifySubmit(input);
        } else {
            const phoneNumber = "+91" + input.mobileNo;
            generateCaptcha();

            const appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    // SMS sent. Prompt user to type the code from the message, then sign the
                    // user in with confirmationResult.confirm(code).
                    window.confirmationResult = confirmationResult;
                    setIsOTPSent(true);
                    setShowLoader(false);
                })
                .catch((error) => {
                    console.log("otp not sended");
                    console.log(error);
                    setShowLoader(false);
                });
        }
    }

    function onVerifySubmit(input) {
        const otpReceived = input.otp;

        window.confirmationResult
            ?.confirm(otpReceived)
            .then((result) => {
                console.log("User signed in successfully.");
                // User signed in successfully.
                const user = result.user;
                user.getIdToken().then(function (idToken) {
                    loginRealmWithJwtToken(idToken);
                });
                setUser(user);
                setShowLoader(false);
                // ...
            })
            .catch((error) => {
                console.log("User couldn't sign in");
                console.log(error);
                setShowLoader(false);
                setMessage("Otp mismatch");
                // User couldn't sign in (bad verification code?)
                // ...
            });
    }

    function generateCaptcha() {
        window.recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha-container",
            {
                size: "invisible",
                callback: (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    // ...
                    // alert("otp send")
                },
                "expired-callback": () => {
                    // Response expired. Ask user to solve reCAPTCHA again.
                    // ...
                },
            },
            auth
        );
    }

    if (isAuthorised == true) {
        if (return_url) {
            if (return_url == "cart") {
                window.location.replace(`/${return_url}`);
            } else {
                window.location.replace(`/cart`);
            }
        } else {
            window.location.replace("/");
        }
    } else {
        return (
            <>
                <Topbar />
                <Menubar />

                <Loginpage>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Para>Login or Create your account</Para>
                        <Label>
                            <Heading>Enter your mobile number*</Heading>

                            <Input
                                type="tel"
                                label="label"
                                {...register("mobileNo", {
                                    required: `Mobile is required`,
                                    pattern: {
                                        value: /^[5-9]{1}[0-9]{9}$/,
                                        message: "Invalid mobile number",
                                    },
                                })}
                                min={0}
                                max={10}
                                step={10}
                                // onKeyDown={(event) => {event.preventDefault()}}
                                pattern="\d+"
                                onWheel={() => document.activeElement.blur()}
                            />
                        </Label>

                        {isOTPSent ? (
                            <Label>
                                <Heading>Verify OTP*</Heading>

                                <Input
                                    type="tel"
                                    label="label"
                                    {...register("otp", {
                                        required: `OTP is required`,
                                    })}
                                />
                            </Label>
                        ) : null}

                        <Btn>
                            <Button type="submit">Send OTP</Button>
                        </Btn>

                        {/* <Text>
                            <Bottomtext>Don't have any account?</Bottomtext>
                            <BtntagAnchor href="/register">
                                Register
                            </BtntagAnchor>
                        </Text> */}
                    </Form>
                </Loginpage>
                <Backdrop
                    sx={{
                        color: "#e50027",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        backgroundColor: "rgba(255, 255, 255, 0.35)",
                    }}
                    open={showLoader}
                >
                    <CircularProgress color="inherit" size={40} />
                </Backdrop>
                <div id="recaptcha-container"></div>
            </>
        );
    }
};

export default Login;
