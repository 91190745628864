import { Category } from "@mui/icons-material"
import { sizeHeight } from "@mui/system"
import styled from "styled-components"
import { useState } from "react"

const Container = styled.div`
width: 100%;
margin:auto;
`
const Header = styled.header`
font-size: 14px;
font-weight: 600;
height: 24px;
display: flex;
align-items: center;
justify-content: space-between;
padding: 15px 20px 15px 15px;
background: rgb(255, 255, 255);
border-bottom: 1px solid rgb(221, 221, 221);
letter-spacing: 1px;
text-transform: uppercase;

`
const Filter  = styled.div`
font-size: 15px;
`
const Text = styled.a`
color: rgb(164, 83, 83);
`
const Main = styled.main`
display: flex;
height:calc(100vh - 118px);

`
const Aside = styled.aside`
flex: 1.5 ;
border-right-width: 0.5px;
border-right-color: rgb(242, 242, 242);
background: rgb(249, 249, 249);
max-height: 100%;
overflow: hidden scroll;
`
const Ul = styled.ul`

`
const Li = styled.li`
font-size: 0.65em;
color: rgb(103, 103, 103);
font-weight: 400;
border-bottom: 1px solid rgb(235, 222, 222);
padding: 20px 5px 20px 20px;
margin: 0px;
text-transform: uppercase;
letter-spacing: 0.3em;
border-left: 5px solid rgb(253, 152, 152);
background: #ececec;
display: flex;
`

const Innerli = styled.li`
flex: 1 1 0%;
font-size: 10px;
color: gray;
`
const Formarea = styled.form`
flex: 2.75;
padding: 20px 0px 0px 20px;
max-height: 100%;
overflow: hidden scroll;
`

const Label = styled.label`
display: ${props => props.clicked ? 'flex' : "flex"};
cursor: pointer;
font-size: 1.175rem;
line-height: 1.25rem;
align-items: center;
max-height: 25px;
margin-bottom: 25px;


`
const Span = styled.div`
margin-left: .75rem;
`
const Input = styled.input`

`
const Innerprice = styled.div`
display: flex;
border: medium none;
border-radius: 3px;
position: relative;
margin-right: 10px;
overflow: hidden;
margin-bottom: 10px;
`
const Labeltext = styled.div`
font-size: 1.175rem;
margin-right: 30px;
display: inline-block;
width: 50px;
`
const Select = styled.select`
background-color: rgb(247, 247, 247);
padding: 8px;
border-radius: 6px;
font-weight: 300;
font-size: 15px;
width: 115px;
`
const Option = styled.option`

`
const Footer = styled.footer`
background: rgb(255, 255, 255);
font-family: Lato, sans-serif;
color: rgb(51, 51, 51);
padding: 5px 20px 5px 15px;
font-size: 14px;
border-top: 1px solid rgb(221, 221, 221);
font-weight: 600;
letter-spacing: 1px;
text-transform: uppercase;
height: 52px;
`
const Wrapperctn = styled.div`
display: flex;
align-items: center;
height: 100%;
`
const Closetext = styled.div`
flex: 1 1 0%;
height: 20px;
text-align: center;
padding: 10px 0px;
`
const Space = styled.div`
width: 1px;
height: 100%;
background: rgb(221, 221, 221);
margin: 0px 10px;
`
const Button = styled.button`
flex: 1 1 0%;
height: 20px;
text-align: center;
padding: 10px 0px;
background: rgb(11, 11, 10);
border-radius: 2px;
color: rgb(255, 255, 255);
`
const CATEGORY = styled.div`

`
const COLOR = styled.div`

`
const SIZE = styled.div`

`
const PRICE = styled.div`

`
const FIlterarea =()=> {

    const category = [
        {
            id:1,
            span:"CRICKET",
        },
        {
            id:2,
            span:"BADMINTION",
        },
        {
            id:3,
            span:"FOOTBALL",
        },
        {
            id:4,
            span:"TENNIS",
        },
        {
            id:5,
            span:"FOOTWEAR",
        },
    ]
    
    const color =[
        {
            id:1,
            span:"BLUE",
        },
        {
            id:2,
            span:"GREY",
        },
        {
            id:3,
            span:"LIGHT GEEY",
        },
      
    ]
    const size = [
        {
            id:1,
            span:"32",
        },
        {
            id:2,
            span:"28",
        },
        {
            id:3,
            span:"33",
        },
    ]

    const [clicked, setClicked] = useState(false);
    const itemClicked = () => {
        setClicked(!clicked);
    }
    console.log("clicked", clicked);

  return (
    <>
    <Container>
    <Header>
        <Filter>Filter By</Filter>
        <Text href="/">Reset</Text>
    </Header>
        <Main>
            <Aside>
                <Ul>
                    <Li  onClick={itemClicked}>
                        <Innerli>Category</Innerli>
                    </Li>
                    <Li>
                        <Innerli>Color</Innerli>
                    </Li>
                    <Li >
                        <Innerli>Size</Innerli>
                    </Li>
                    <Li>
                        <Innerli>Price</Innerli>
                    </Li>
                </Ul>
            </Aside>

            <Formarea>
                <CATEGORY>                    {
                        category?.map((item, index)=>{
                            return(
                                <Label 
                                clicked={clicked}>
                                    <Input type="checkbox" name="category" readonly="" value="mnm"/>
                                    <Span>{item.span}</Span>
                                </Label>
                            )
                        })
                    }
                </CATEGORY>

                {/* <COLOR>
                    {
                        color?.map((item, index)=>{
                            return(
                                <Label >
                                    <Input type="checkbox" name="category" readonly="" value="mnm"/>
                                    <Span>{item.span}</Span>
                                </Label>
                            )
                        })
                    }
                </COLOR> */}

                {/* <SIZE>
                    {
                        size?.map((item, index)=>{
                            return(
                            <Label>
                                <Input type="checkbox" name="category" readonly="" value="mnm"/>
                                <Span>{item.span}</Span>
                            </Label>
                            )
                        })
                    }

                </SIZE> */}

                {/* <PRICE>
                    <Innerprice>
                        <Labeltext>Min</Labeltext>
                        <Select name="minValue">
                            <Option value="0"> 0</Option>
                            <Option value="100"> 100</Option>
                            <Option value="500"> 500</Option>
                            <Option value="700"> 700</Option>
                        </Select>
                    </Innerprice>

                    <Innerprice>
                        <Labeltext>Max</Labeltext>
                        <Select name="maxValue">
                            <Option value="1000"> 1000</Option>
                            <Option value="700"> 700</Option>
                            <Option value="5000"> 5000</Option>
                            <Option value="1000"> 10000</Option>
                            <Option value="20000"> 20000</Option>
                        </Select>
                    </Innerprice>
                </PRICE> */}
            </Formarea>
        </Main>

        <Footer>
            <Wrapperctn>
                <Closetext>Close</Closetext>
                <Space></Space>
                <Button as="a" href="/searchold">Apply</Button>
            </Wrapperctn>
        </Footer>

    </Container>
    </>
  )
}

export default FIlterarea