const multplier = 4;

// export const orderQtyRef = (n) => {
//     return multplier * n;
// };

export const availableQtyRef = (n) => {
    return multplier * n;
};

export const rateTypeRef = (n) => {
    return multplier * n + 1;
};

export const discountRef = (n) => {
    return multplier * n + 2;
};

export const priceByRateTypeRef = (n) => {
    return multplier * n + 5;
};

// export const netRateRef = (n) => {
//     return multplier * n + 5;
// };

// export const dpRef = (n) => {
//     return multplier * n + 6;
// };

// export const mrpRef = (n) => {
//     return multplier * n + 7;
// };

// export const grossMarginRef = (n) => {
//     return multplier * n + 8;
// };

// export const gstRef = (n) => {
//     return multplier * n + 9;
// };

// export const incTaxRef = (n) => {
//     return multplier * n + 10;
// };

// export const netMarginRef = (n) => {
//     return multplier * n + 11;
// };

// export const finalPriceRef = (n) => {
//     return multplier * n + 12;
// };

// export const totalPriceRef = (n) => {
//     return multplier * n + 13;
// };
