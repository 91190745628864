import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
    height: 35px;
    background: #ff6e31;
`;
const Wrapper = styled.div`
    flex: 2;
    display: flex;
    justify-content: center;
`;
const MenuItem = styled.a`
    cursor: pointer;
    display: flex;
    height: 35px;
    align-items: center;
    color: white;
    padding: 0px 8px;
    font-size: 14px;
    font-weight: bold;
`;
const Navbar = () => {
    return (
        <Container>
            <Wrapper>
                <MenuItem
                    href="/pricelist"
                    style={{ textDecoration: "none", color: "#fff" }}
                >
                    PRICELIST
                </MenuItem>

                <MenuItem
                    href="/catalogue"
                    style={{ textDecoration: "none", color: "#fff" }}
                >
                    CATALOGE
                </MenuItem>
            </Wrapper>
        </Container>
    );
};

export default Navbar;
