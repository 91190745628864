import { useEffect, useState  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"

import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import Announcement from '../../Components/Announcement/Announcement'
import Topbar from '../../Components/Topbar/Topbar'
import Menubar from '../../Components/Menubar/Menubar'
import AppSection from '../../Components/AppSection/AppSection'
import Product from '../../Components/Product/Product'
import styled from "styled-components"
import { FilterSharp } from '@mui/icons-material';

const Loginpage = styled.div`
width:1050px;
margin:33px auto;
@media(max-width:1050px)
{
  width:98%;
}
`
const Form =styled.form`
width: 46%;
margin: auto;
background: #eee;
border-radius: 5px;
padding: 28px 24px;
@media(max-width:739px)
{
  width:64%;
}
@media(max-width:442px)
{
  width:86%;
}
`
const TopHeading = styled.div`
margin:0px;
font-size: 17px;
padding-bottom: 18px;
@media(max-width:492px)
{
  font-size: 12px;
}

`
const Para = styled.div`
font-size: 13px;
`
const BottomPara =styled.div`
font-size: 14px;
margin-top:4px;
`
const Input = styled.input`
width: 98%;
padding:3px 5px;
border: 1px solid #c8c8c8;
height: 36px;
border-radius: 4px;
&::placeholder {
  color: #c8c8c8;
  opacity: 1;
}
&:focus {
    outline: none;
    box-shadow: 0px 0px 2px red;
}
&:: -webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
`
const Icon =styled.div`
width:17px;
position: absolute;
right: 8px;
top: 8px;
`
const Label = styled.label`
margin-top:8px;
margin-bottom:10px;
`
const Inputarea = styled.div`
position: relative;
`
const Heading =styled.h2`
font-size: 13px;
font-weight: bold;
cursor: pointer;
margin-bottom: 2px;
margin-top: 15px;
`
const Text = styled.div`
text-align: center;
font-size: 13px;
font-weight: bold;
`
const Bottomtext = styled.div`
margin-right: 8px;
`
const Btn = styled.div`
margin: 16px 0px;
width:100%;
`
const Button = styled.button`
display: block;
color: white;
font-size:13px;
padding: 12px 22px;
background: rgb(19, 19, 18);
text-align: center;
border-radius: 5px;
box-shadow: 3px 3px 2px #cdc9c9;
&:hover
{
  background: rgb(65, 65, 64);
}

`
const TopimgAnchor = styled.a`
text-align: center;
display:block;
padding-bottom: 10px;
`
const Image = styled.img`
width:120px;
`
const BtntagAnchor =styled.a`
color: black;
font-size: 17px;
text-align: center;
padding: 3px 0px;
display: block;

`
const logo = "https://vishwasports.s3.ap-south-1.amazonaws.com/gravity.png"

const Register= () => {

    return (
      <>   
      
    <Topbar/>
    <Menubar />

      <Loginpage>

          <Form>

              {/* <TopimgAnchor href="/">
                  <Image src={logo}/>
              </TopimgAnchor> */}
              <Label>
              <Heading>First Name*</Heading> 
              <Input type="name" label="label" for="name" autocomplete="off"spellcheck="false"aria-invalid="false" 
       
                      
              />
              </Label>

              <Label>
              <Heading>Last Name*</Heading> 
              <Input type="name" label="label" for="name" autocomplete="off"spellcheck="false"aria-invalid="false" 
       
              />
              </Label>

              <Label>
              <Heading>Mobile Number*</Heading> 
              <Input type="mobile no" label="label" for="mobile no" autocomplete="off"spellcheck="false"aria-invalid="false" 
         
              />
              </Label>

              <Label>
              <Heading>Email ID*</Heading> 
              <Input type="email" label="label" for="email" autocomplete="off"spellcheck="false"aria-invalid="false" 
     
              />
              </Label>

              <Label>
              <Heading>Password*</Heading>
                <Inputarea>
                  <Input id='password' label="label" for="password" 
                  autocomplete="off"spellcheck="false"aria-invalid="false"
             
                  />
                  <Icon>
                    <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="VisibilityOffIcon">
                        <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z">
                        </path>
                    </svg>
                  </Icon>
                </Inputarea>
              </Label>

              <Label>
              <Heading>Conform Password*</Heading>
                <Inputarea>
                  <Input id='password' label="label" for="password" 
                  autocomplete="off"spellcheck="false"aria-invalid="false"
             
                  />
                  <Icon>
                    <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="VisibilityOffIcon">
                        <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78 3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z">
                        </path>
                    </svg>
                  </Icon>
                </Inputarea>
              </Label>
          
          <BottomPara>* Required field</BottomPara>
              <Btn>
                  <Button as="a" href="/" type="submit">
                  Register
                           
                  </Button>
              </Btn>  
              
                <Text>Or</Text>
                <BtntagAnchor href="/login" type="submit">Login</BtntagAnchor>
              
          </Form>

      </Loginpage>
 

               

</>
)
}

export default Register;

