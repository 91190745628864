import styled from "styled-components";

import AccountLayout from "../../../Components/MyAccount/AccountLayout";
import Navbar from "../../../Components/Navbar/Navbar";

import { Colors, UI } from "../../../Constants/Index";

const Container = styled.main`
    max-width: 767px;
    display: flex;
    justify-content: center;
    height: 100vh;
    margin: auto;
    margin-top: ${parseInt(UI.navBarHeight) + parseInt(UI.topBarHeight)}px;
    @media (max-width: 550px) {
        width: 90%;
    }
`;

const Wrapper = styled.div`
    display: flex;
    padding-top: 20px;
    @media (max-width: 767) {
        min-width: 0;
        width: 90%;
    }
`;

const Section = styled.a`
    min-height:100px;
    background:#ddd;
    width:100%;
    margin-top:20px;
    margin-bottom:10px;
    display:flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, rgba(89,95,212,255) , rgba(90,61,180,255));
    border-radius:5px;
    color:#fff;
    font-size:40px;
    font-family: 'Heebo', sans-serif;
    font-weight:600;
    text-decoration:none;
    &: a:visited {
        text-decoration: none;
    }
    &: a:visited {
        text-decoration: none;
    }
    a:link {
        text-decoration: none;
    }
`;
const MenuCtn = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 925px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
        row-gap: 10px;
        column-gap: 10px;
    }
`;

const LI = styled.li`
    min-height: 30px;
    background: #ddd;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
        to right,
        rgba(89, 95, 212, 255),
        rgba(90, 61, 180, 255)
    );
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-family: "Heebo", sans-serif;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    line-height: 1;
    padding: 5px;
    > a {
        color: #fff;
    }
`;
const Menu = styled.a`
    max-height: 100px;
    height: 100px;
    background: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
        to right,
        rgba(89, 95, 212, 255),
        rgba(90, 61, 180, 255)
    );
    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    font-family: "Heebo", sans-serif;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    line-height: 1;
    padding: 5px;
    max-width: 200px;
    width: 200px;
    @media (max-width: 467px) {
        width: 150px;
    }
    @media (max-width: 367px) {
        width: 130px;
    }
    @media (max-width: 310px) {
        width: 100px;
        height: 70px;
    }
`;

const Button = styled.button``;

const Home = () => {
    return (
        <Container>
            <Navbar />
            <Wrapper>
                <AccountLayout>
                    <div>Dashboard</div>
                </AccountLayout>
            </Wrapper>
        </Container>
    );
};

export default Home;
