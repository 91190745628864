import styled from "styled-components";
import Topbar from "../../../Components/Topbar/Topbar";
import Menubar from "../../../Components/Menubar/Menubar";
import Footer from "../../../Components/Layout/Footer";
import Nav from "../Nav";

import AccountLayout from "../../../Components/MyAccount/AccountLayout";

const Container = styled.div`
    margin: 0px auto;
    width: 1349px;
    @media (max-width: 1050px) {
        width: 98%;
    }
`;
const Wrapper = styled.div`
    display: flex;
    padding-top: 10px;
`;
const Main = styled.div`
    flex: 6 1 0%;
`;
const Heading = styled.h2`
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 6px;
    padding-left: 3rem;
`;

const Form = styled.form`
    padding: 1.5rem 3rem;
`;
const InputCtn = styled.div`
    margin-bottom: 12px;
    position: relative;
    height: 80px;
`;
const Label = styled.label`
    margin-bottom: 5px;
    letter-spacing: 0.5px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
`;
const Input = styled.input`
    width: 98%;
    padding: 3px 5px;
    margin-top: 6px;
    border: 1px solid #c8c8c8;
    height: 36px;
    font-size: 15px;
    border-radius: 4px;
    &::placeholder {
        color: #c8c8c8;
        opacity: 1;
    }
    &:focus {
        outline: none;
        box-shadow: 0px 0px 2px red;
    }
    &:: -webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
const Text = styled.div`
    font-size: 13px;
    margin-top: 6px;
    margin-bottom: 8px;
`;
const Btn = styled.div`
    margin: 8px 0px;
`;
const Button = styled.button`
    width: 100%;
    display: block;
    background: rgb(20, 20, 19);
    color: white;
    text-align: center;
    font-size: 13px;
    padding: 12px 22px;
    border-radius: 5px;
    box-shadow: rgb(205, 201, 201) 3px 3px 2px;
`;
const Myaccount = () => {
    return (
        <>
            <Topbar />
            <Menubar />
            <Container>
                <Wrapper>
                    <AccountLayout>
                        <Nav />
                        <Main>
                            <Heading>Profile</Heading>
                            <Form>
                                <InputCtn>
                                    <Label>
                                        Name*
                                        <Input
                                            id="name"
                                            name="name"
                                            type="text"
                                            label="label"
                                            aria-invalid="false"
                                            value="test"
                                        />
                                    </Label>
                                </InputCtn>

                                <InputCtn>
                                    <Label>
                                        Mobile Number*
                                        <Input
                                            id="mobile"
                                            name="phone"
                                            type="tel"
                                            label="label"
                                            aria-invalid="false"
                                            min="0"
                                            max="10"
                                            step="10"
                                            pattern="\d+"
                                            class="sc-jPVYhN jHFLuP"
                                            value="8787878787"
                                        />
                                    </Label>
                                </InputCtn>

                                <Text>* Requried Fields</Text>
                                <Btn>
                                    <Button type="submit" href="/">
                                        UPDATE
                                    </Button>
                                </Btn>
                            </Form>
                        </Main>
                    </AccountLayout>
                </Wrapper>
            </Container>
            <Footer />
        </>
    );
};
export default Myaccount;
