import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Topbar from "../../Components/Topbar/Topbar";
import Menubar from "../../Components/Menubar/Menubar";
import Footer from "../../Components/Layout/Footer";

const Container = styled.div`
    margin: auto;
    width: 1050px;
    padding: 26px 0px;
    @media (max-width: 1050px) {
        width: 94%;
    }
`;
const Wrapper = styled.div`
    padding: 0px 33px;
    @media (max-width: 447px) {
        padding: 0px 12px;
    }
`;
const WrapperCtn = styled.div`
    margin: 8px 0px;
`;
const Topheading = styled.h1`
    font-size: 2rem;
    font-weight: 600;
    padding-bottom: 8px;
    padding-top: 28px;
    @media (max-width: 447px) {
        font-size: 26px;
    }
`;
const Heading = styled.h2`
    font-size: 20px;
    font-weight: 600;
    padding-top: 28px;
    @media (max-width: 447px) {
        font-size: 17px;
    }
`;
const Para = styled.div`
    font-size: 15px;
    margin-top: 7px;
    text-align: justify;
    @media (max-width: 447px) {
        font-size: 14px;
    }
`;
const Bottompara = styled.div`
    color: #5a5353;
    font-size: 14px;
    margin-top: 12px;
    text-align: justify;
    @media (max-width: 447px) {
        font-size: 13px;
    }
`;
const Innerwrapper = styled.div`
    margin-top: 18px;
    padding-left: 4rem;
    padding-right: 4rem;
    @media (max-width: 767px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
`;
const company = "Vishwa Sport's";

const DisclaimerPage = () => {
    return (
        <>
            <Topbar />
            <Menubar />

            <Container className="px-4">
                <Wrapper>
                    <Topheading>Disclaimer</Topheading>
                </Wrapper>
            </Container>
            <Footer />
        </>
    );
};

export default DisclaimerPage;
