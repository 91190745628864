import { createRef } from "react";

export const inputRef = (cartItems) => {
    const inputRefs = [];
    let inputCount = cartItems?.length * 2;
    for (let i = 0; i < inputCount; i++) {
        inputRefs[i] = createRef(null);
    }
    return inputRefs;
};
