import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListItemSecondaryAction } from "@mui/material";
import styled from "styled-components";
import MuiButton from "@mui/material/Button";
import { styled as MuiStyled } from "@mui/material/styles";

import ProductSkeleton from "../../Components/Skeleton/ProductSkeleton";

import Product_m from "../../Models/Product_m";
import { addToCart, removeFromCart } from "../../Store/Action/Index";
import { Colors, inputRef } from "../../Constants/Index";

const Filters = styled.div`
    display: flex;
    padding: 1px 11px;
    margin-bottom: 2px;
    justify-content: space-between;
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    color: #282c3f;
    font-size: 14px;
    letter-spacing: 0.5px;
    @media (max-width: 642px) {
        display: flex;
        padding: 3px 16px;
        border-radius: 3px;
    }
`;
const Textarea = styled.div`
    display: flex;
    font-family: initial;
    align-items: center;
    width: 70%;
    @media (max-width: 870px) {
        font-size: 13px;
    }
    @media (max-width: 642px) {
        font-size: 14px;
    }
`;
const Div = styled.div`
    display: flex;
    flex: 1;
`;

const Description = styled.div`
    margin-left: 6px;
    padding: 0 10px;
    flex: 4;
    @media (max-width: 642px) {
        margin-left: 0px;
    }
`;
const Rupee = styled.div`
    margin-left: 6px;
    padding: 0 10px;
    display: flex;
    @media (max-width: 642px) {
        width: 100%;
        margin-left: 0px;
        padding: 0 10px;
    }
`;
const CartButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    @media (max-width: 642px) {
        flex-direction: column;
        align-items: flex-end;
    }
`;
// const Button = styled.button`
//     font-size: 14px;
//     display: block;
//     text-decoration: none;
//     text-align: center;
//     padding: 0 12px;
//     background: #cbcbcb;
//     color: #3a3a3a;
//     border-radius: 5px;
//     font-weight: 600 @media (max-width: 900px) {
//         font-size: 11px;
//     }
//     @media (max-width: 642px) {
//         margin-top: 10px;
//         font-size: 14px;
//     }
//     &:hover {
//         background: #d1cfcf;
//     }
// `;

export const Button = styled(MuiButton)((props) => ({
    background: "black",
}));

const Headingtext = styled.h2`
    font-size: 14px;
    color: gray;
    font-weight: bold;
    flex: 1;
    @media (max-width: 525px) {
        font-size: 11px;
    }
    @media (max-width: 642px) {
        margin-left: 0px;
    }
`;

const Srno = styled.span`
    font-size: 14px;
    color: gray;
    font-weight: bold;
    flex: 0.25;
    @media (max-width: 642px) {
        margin-left: 0px;
    }
`;

const Input = styled.input`
    width: 50px;
`;

const Search = ({ product, isLoading }) => {
    const dispatch = useDispatch();
    const CART = useSelector((state) => state.Cart);

    const [quantity, setQuantity] = useState(1);

    const transformedCartItems = [];
    const cartItems = useSelector((state) => {
        for (const key in state.Cart.items) {
            transformedCartItems.push({
                product_id: key,
                productId: state.Cart.items[key].productId,
                product_name: state.Cart.items[key].product_name,
                brand_name: state.Cart.items[key].brandName,
                mrp: state.Cart.items[key].mrp,
                dealer_price: state.Cart.items[key].dp,
                qty: state.Cart.items[key].quantity,
            });
        }
        return transformedCartItems;
    });

    const result_product = product?.map((item) => ({
        ...item,
        isAddedToCart: cartItems?.some((item2) => item2.productId == item._id),
        qty: 1,
    }));

    const addToCartHandler = (item, quantity, mrp, dealer_price, gst) => {
        dispatch(addToCart(item, quantity, mrp, dealer_price, gst));
    };

    const removeProductHandler = (product_id) => {
        dispatch(removeFromCart(product_id));
    };

    const inputRefs = inputRef(result_product);

    return (
        <>
            {isLoading ? (
                <ProductSkeleton limit={20} />
            ) : (
                result_product?.map((item, index) => {
                    const productObj = new Product_m(
                        item?._id,
                        item?.brand,
                        item?.product_name,
                        item?.dealer_price,
                        item?.mrp,
                        item?.gst,
                        item?.isAddedToCart
                    );

                    return (
                        <Filters key={item._id}>
                            <Textarea>
                                <Srno>{index + 1}</Srno>
                                <Headingtext>
                                    {productObj.getBrandName}
                                </Headingtext>
                                <Description>
                                    {productObj.getProductName}
                                </Description>
                            </Textarea>
                            <CartButton>
                                <Div>
                                    <Rupee>₹{productObj.getmrpPrice} </Rupee>
                                </Div>
                                <Div>
                                    <Rupee>
                                        Qty:{" "}
                                        <Input
                                            type="number"
                                            defaultValue={item.qty}
                                            onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                    console.log(e.target.value);
                                                }
                                            }}
                                            min={1}
                                        />
                                    </Rupee>
                                </Div>
                                <Div>
                                    {productObj.getIsAddedToCart ? (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                width: "100%",
                                                height: 18,
                                                fontSize: 10,
                                                fontWeight: 700,
                                                color: "#444",
                                                background: "#ddd",
                                                "&:hover": {
                                                    background: "#eee",
                                                },
                                            }}
                                            onClick={() =>
                                                removeProductHandler(
                                                    productObj.getProductId
                                                )
                                            }
                                        >
                                            Remove
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                width: "100%",
                                                height: 18,
                                                fontSize: 9,
                                                fontWeight: 700,
                                                color: "#fff",
                                            }}
                                            onClick={() =>
                                                addToCartHandler(
                                                    productObj.getProduct,
                                                    quantity,
                                                    productObj.getmrpPrice,
                                                    productObj.getDealerPrice,
                                                    productObj.getGst
                                                )
                                            }
                                        >
                                            Add to Quote
                                        </Button>
                                    )}
                                </Div>
                            </CartButton>
                        </Filters>
                    );
                })
            )}
        </>
    );
};

export default Search;
