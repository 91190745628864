export const getfilterId = (string) => {
    if (string == undefined) {
        return "";
    } else {
        const result = string.split("-");
        return result[1];
    }
};

export const sortStringInAscending = (array) => {
    return array.sort((a, b) =>
        a.name < b.name ? -1 : Number(a.name > b.name)
    );
};

export const upperCaseTxt = (string) => {
    const arr = string.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
};

export const getSlug = (string) => {
    return string?.split("-").join(" ").toUpperCase();
};
