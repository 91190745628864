import axios from "axios";
import realmApp from "../Config/Config";

const currentUser = realmApp?.currentUser;
const refreshTokenValue = localStorage.getItem("refreshTokenValue");

export const base_url = `https://ap-south-1.aws.data.mongodb-api.com/app/vishwa-sports-b2b-jdupr/endpoint`;
export const refresh_url = `https://realm.mongodb.com/api/client/v2.0/auth/session`;

export const refreshToken = async () => {
    try {
        const data = await axiosPublic.post(refresh_url);
        return data;
    } catch (error) {
        return error;
    }
};

const refreshHeader = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${refreshTokenValue}`,
};

export const axiosPublic = axios.create({
    baseURL: refresh_url,
    headers: refreshHeader,
});
