import * as Realm from "realm-web";

const appId = "vishwa-sports-b2b-jdupr";
const appConfig = {
	id: appId,
	timeout: 10000,
};

// const auth = getAuth(app);
const RealmInstance = new Realm.App(appConfig);

export default RealmInstance;
