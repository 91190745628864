import styled from "styled-components"
import AppStore from "../../Assets/app-store.jpg"
import PlayStore from "../../Assets/play-store.jpg"
const {innerWidth:width, innerHeight:height} = window;

const Container = styled.div`
  width:1500px;
  margin:auto;
  background-color:#fbf1e9;
  margin-top:70px;
  padding:0px 22px;
  @media(max-width:1500px)
  {
    width:95%
  }
  @media(max-width:1050px)
  {
    width:92%
  }
  @media(max-width:767px)
  {
    width:87%
  }
  

`

const Wrapper = styled.div`
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    padding-left: 6rem;
    padding-right: 6rem;
    @media(max-width:767px)
    {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    @media(max-width:521px)
    {
      padding-left: 3rem;
      padding-right: 3em;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
 
`

const Heading = styled.h1`
  padding: 5px 0px 5px 0px;
  line-height:50px;
  @media(max-width:521px)
  {
    line-height:22px;
    font-size:24px;
  }
  @media(max-width:521px)
  {
    font-size:20px;
  }

`

const Heading2 = styled.h1`
  padding: 5px 0px 5px 0px;
  font-size:50px;
  font-weight:500;
  @media(max-width:767px)
  {
    font-size:30px;
  }
  @media(max-width:521px)
  {
    font-size:20px;
  }

`

const HighLight = styled.span`
  font-weight:700;
  line-height:50px;
`

const ImgContainer = styled.div`
    display:flex;
    padding:50px 0;
    @media(max-width:498px)
    {
      padding: 12px 0px;
    }
`

const Image = styled.img`
    margin-right:10px
    
`

const AppSection = () => {
    
  
  return (
    <Container>
        <Wrapper>
            <Heading>The Vishwa Sports App</Heading>
            <Heading2>Share Your <HighLight>Ideas</HighLight> & Shop </Heading2>
            <Heading2>Endless <HighLight>Inspiration</HighLight> </Heading2>
            {
              width > 498 ?
              <ImgContainer>
                <Image src={AppStore} width={175}  />
                <Image src={PlayStore} width={175}  />
              </ImgContainer>
              :
              <ImgContainer>
                <Image src={AppStore} width={105}  />
                <Image src={PlayStore} width={105}  />
              </ImgContainer>
            }
            
        </Wrapper>
    </Container>
  )
}

export default AppSection